import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const BackButton = () => {
  const navigate = useNavigate();


  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Button
      variant="outlined"
      color="primary"
      startIcon={<ArrowBackIcon />}
      onClick={handleBack}
      sx={{mr:1}}
    >
      Atrás
    </Button>
  );
};

export default BackButton;
