/*set components*/
import Dashboard  from './DashboardAdmin';
import Clientes  from './Clientes';
import Services  from './Servicios';
import Productos  from './Productos';
import Cotizaciones  from './Cotizaciones';
import Content  from './Content';
import Profile  from './Profile';
import Vehiculos  from './Vehiculos';
import Transportadores  from './Transportadores';
import Configuracion  from './Configuracion';


/*icons*/
import Face3Icon from '@mui/icons-material/Face3';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SellIcon from '@mui/icons-material/Sell';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import CommuteIcon from '@mui/icons-material/Commute';
import EngineeringIcon from '@mui/icons-material/Engineering';
import SettingsIcon from '@mui/icons-material/Settings';


/*set icons*/
import HomeIcon from '@mui/icons-material/Home';

export const routes_modules = [
  {
    name: "Home",
    path: "/",
    component: Dashboard,
    menu: false,
    private: true,
    icon: <HomeIcon />,
  },
  {
    name: "Home",
    path: "home",
    component: Dashboard,
    menu: true,
    private: true,
    icon: <HomeIcon />,
  },
  {
    name: "Usuarios",
    url: "clientes",
    path: "clientes/*",
    component: Clientes,
    menu: true,
    private: true,
    icon: <Face3Icon />,
  },
  {
    name: "Transportadores",
    url: "transportadores",
    path: "transportadores/*",
    component: Transportadores,
    menu: true,
    private: true,
    icon: <EngineeringIcon />,
  },
  {
    name: "Vehículos",
    url: "vehiculos",
    path: "vehiculos/*",
    component: Vehiculos,
    menu: false,
    private: true,
    icon: <LocalShippingIcon />,
  },
  {
    name: "Servicios",
    url: "services",
    path: "services/*",
    component: Services,
    menu: true,
    private: true,
    icon: <CommuteIcon />,
  },
  {
    name: "Configuración",
    url: "configuracion",
    path: "configuracion/*",
    component: Configuracion,
    menu: true,
    private: true,
    icon: <SettingsIcon />,
  },
  {
    name: "Profile",
    url: "profile",
    path: "profile/*",
    component: Profile,
    menu: false,
    private: true,
    icon: <Face3Icon />,
  },
  {
    name: "Productos",
    url: "productos",
    path: "productos/*",
    component: Productos,
    menu: false,
    private: true,
    icon: <ConfirmationNumberIcon />,
  },
  {
    name: "Cotizaciones",
    url: "cotizaciones",
    path: "cotizaciones/*",
    component: Cotizaciones,
    menu: false,
    private: true,
    icon: <SellIcon />,
  },
  {
    name: "Contenido",
    url: "content",
    path: "content/*",
    component: Content,
    menu: false,
    private: true,
    icon: <NewspaperIcon />,
  },  
];
