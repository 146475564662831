import Container from '../../components/Theme/Auth';
import Input from '../../components/Input';
import InputPassword from '../../components/Input/InputPassword';
import Submit from '../../components/Button/Loading';
import Button from '@mui/material/Button';

import { useContext } from 'react';
import useFormData      from '../../hooks/useFormData';
import useAxios         from '../../hooks/useAxios';
import useAsyncStorage  from '../../hooks/useAsyncStorage';
import StateContext from '../../helpers/ContextState';
import {useNavigate, useLocation, NavLink } from "react-router-dom";
import { Grid, Typography } from '@mui/material';

const Login=()=>{
  const master                                =   useAsyncStorage("master");
  const { formData, onChange, setFormData }   =   useFormData();
  const axios                                 =   useAxios();
  const context                               =   useContext(StateContext);
  const userStorage                           =   useAsyncStorage("user");
  const location                              =   useLocation();
  const navigate                              =   useNavigate();

  const onSubmit=(e)=>{
    e.preventDefault()
    sendDataBK();
  }

  //recover_password
  const sendDataBK=(__data)=>{
    axios.postData({...formData,...__data}).then((response)=>{
      if (response&&response.status&&response.status==="success") {
        return navigate("/auth/login");  
      }
    })
  }

  return  <Container onSubmit={onSubmit}>
            <Grid sx={{mb:2}}>
              <Typography>Ingresa el código de verificación enviado a tu email.</Typography>
            </Grid>
            <Input autoComplete="off" sx={{mb:2,width:"100%"}} required onChange={onChange} fullWidth name="email" type="email" label="Correo electrónico"/>
            <Input autoComplete="off" sx={{mb:2,width:"100%"}} required onChange={onChange}  fullWidth name="register_code" type="text" label="Código de verificación"/>
            <InputPassword autoComplete="off" sx={{mb:2,width:"100%"}} required onChange={onChange}  fullWidth name="password" type="password" label="Nueva Contraseña"/>
            <Submit fullWidth type="submit" label="Recuperar Contraseña"/>
            <Button to="/auth/register" component={NavLink}>Crear Cuenta</Button>
            <Button to="/auth" component={NavLink}>Iniciar sesión</Button>
          </Container>
}
export default Login
