import React, { useEffect, useState }          from  'react';
import Grid           from  '@mui/material/Grid';
import { Typography } from  '@mui/material';
import Tabs           from  '../../../components/Tabs/Services';
import StateContext   from  '../../../helpers/ContextState';
import {formatearFecha}   from  '../../../helpers/functions';
import useAxios       from  '../../../hooks/useAxios';
import useFormData    from  '../../../hooks/useFormData';

const Edit = ({data}) => {
  const axios             =   useAxios();
  const context                             =   React.useContext(StateContext);
  const [valorDeclarado,setValorDeclarado]  =   useState(0)
  const [peso,setPeso]                      =   useState(0)
  const { formData,
          setFormData }                     =   useFormData();

  const onSubmit=(e)=>{
    e.preventDefault()
    axios.putData(formData).then((response)=>{
      if (response&&response.data&&response.data.id) {
        context.setOpenMessage(response.message)
        //getInit(true)
      }
    })
  }
  
  const json  = JSON.parse(data.json)

  useEffect(()=>{
    if (json.merchandise_value&&json.merchandise_value.length>0) {
      let sum   = 0;
      let peso_ = 0;
      json.merchandise_value.map((row)=>{
        return  sum+= parseFloat(row);
      })  
      json.merchandise_value.map((row)=>{
        return peso_+=parseFloat(row)        
      })  
      setValorDeclarado(sum)
      setPeso(peso_)
    }
    
  },[json.merchandise_value])

  return (  
              <Grid sx={{pl:2,pr:2}}>
                <Typography variant="h5" sx={{background:"#f2f2f2"}}>
                  Datos de Servicio                  
                </Typography>
                <Grid container spacing={2} >
                  <Grid item xs={12} md={5}>
                    Usuario: <b>{ data.cliente}</b>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    Código:<b>{data.id}</b>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    Fecha:<b>{ formatearFecha(data.updated_at)} </b>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    Estado Servicio: <b>{ data.status }</b>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    Valor declarado: <Grid>
                      <b>{ valorDeclarado }</b>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    Peso declarado: <Grid>
                      <b>{ peso }</b>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    Descripción mercancía: <Grid><b>{ json.merchandise_descripction }</b></Grid>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    Condicioones transporte: <Grid><b>{ json.merchandise_especial_descripction }</b></Grid>
                  </Grid>
                </Grid>
                <Grid sx={{mt:2}}>
                  <Tabs data={data}/>
                </Grid>            
              </Grid>            
          );
};

export default Edit;
