import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Documents from "../Uploads/Documents";
import ClientesBasic from './Components/ClientesBasic';
import DatosFacturacion from '../DatosFacturacion/SideAdmin';
import { Grid } from '@mui/material';
import Table2 from "../../components/Table/Table5";
import SelectStatus from "../../components/Button/Select";
import useAxios      from '../../hooks/useAxios';


function CustomTabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1, height:400, overflow:"hidden", overflowY:"scroll" }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

let getInit=false

export default function BasicTabs({data}) {
  const axios                       =   useAxios();
  const [documents, setDocuments]   =   React.useState([]);
  const [value, setValue]           =   React.useState(0);
  const [inputs,setInputs]          =   React.useState({})
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  getInit=()=>{
    const BACKEND = "api/" + process.env.REACT_APP_BACKEND_VERSION;

    axios.getData({},BACKEND+"/dashboard/documentos/doc_adiconales_usuario_"+data.id).then((response)=>{
      if (response&&response.data) {
        setDocuments(response.data)       
      }    
    })
  }

  React.useEffect(()=>{
    getInit()
  },[])

  const handleChangeDocuments=(data)=>{
    getInit();
    if (Object.entries(data)&&Object.entries(data)[0]&&Object.entries(data)[0][1]) {
      setDocuments(Object.entries(data)[0][1]) 
    } 
       
  }

  const onSubmitStore=async(e,data)=>{
    e.preventDefault();
    await axios.postData(data,"api/v1/dashboard/billing").then((response)=>{
      if (response&&response.data) {        
        
      }
      getInit();
    })
  }


  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{background:"#f2f2f2"}}>
          <Tab label="Información básica" {...a11yProps(0)} />
          <Tab label="Datos de facturación" {...a11yProps(1)} />
          <Tab label="Documentos adicionales" {...a11yProps(2)} />
          <Tab label="Estatus del usuario" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <ClientesBasic data={data}/>        
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <DatosFacturacion inputs={data} onSubmit={onSubmitStore} setData={setInputs}/>                    
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Documents  id={"doc_adiconales_usuario_"+data.id} 
                        base={"doc_adiconales_usuario_"+data.id}
                        onUpload={handleChangeDocuments}/>
          </Grid>
          <Grid item xs={12} md={8}>
            <Table2 data={documents["doc_adiconales_usuario_"+data.id]}/>                                                    
          </Grid>
        </Grid>       
      </CustomTabPanel>      
      <CustomTabPanel value={value} index={3}>
        <SelectStatus id={data.id} defaultValue={data.status}/>        
      </CustomTabPanel>
    </Box>
  );
}
