import { usePlacesWidget } from "react-google-autocomplete";
import { TextField, Box, InputLabel } from '@mui/material';
const Component=({   label, size, onChange, type, name }) => {

const apitemp       =   process.env.REACT_APP_GOOGLEMAP

    let types       =   [];

    if (type) {
        types.push(type);
    }

    const { ref: materialRef } = usePlacesWidget({
        apiKey: apitemp,
        strictBounds: false,
        onPlaceSelected: (place) => onChange(place,name),
        options: {
            /*types: ["locality"],*/
            //types: ['address'], 
            types: types, 
            componentRestrictions: { country: "CO" }, 
        },
    });

    return  <Box sx={{mb:2}}>
                {
                    label&&(
                        <InputLabel >
                            {label}
                        </InputLabel>
                    )
                }
                <TextField
                    size={size}
                    fullWidth
                    color="secondary"
                    variant="outlined"
                    inputRef={materialRef}
                    placeholder="Indica una ciudad"
                />
            </Box>    


}

export default Component



