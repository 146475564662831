import React from 'react';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import LoopIcon from '@mui/icons-material/Loop';
import './index.css'
import { Typography } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ open, setOpen, getInit }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth={open.maxWidth||"md"} // Aumento del ancho máximo
      open={open ? true : false}
      onClose={handleClose}
      TransitionComponent={Transition}
      className={open.loading?"transparent-dialog":""}
    >
      <Grid justifyContent="center"> {/* Eliminación de la propiedad `container` */}
        <Grid item xs={12}>
          {
            open&&open.component && typeof open.component !== 'function'&&(
              open.component
            )
          }
          {
            !open.id&&!open.component&&(
              <Grid align="center" sx={{height:200}}>
                <Grid>
                  <Grid>
                    <LoopIcon className="spin" sx={{fontSize:150}} color="primary"/>
                  </Grid>  
                  <Grid component={Typography} sx={{fontSize:15,color:"#fff"}}>
                    Por favor espere...
                  </Grid>
                </Grid>
              </Grid>
            )
          }
            
          {
            open&&open.component && typeof open.component === 'function'&&(
              <open.component data={open} handleClose={handleClose} getInit={getInit} subFixed={open.subFixed}  />
            )
          }
        </Grid>
      </Grid>
    </Dialog>
  );
}
