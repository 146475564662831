import React from 'react';
import Container from '../../components/Theme/AuthForm';
import { Button, Grid, Typography } from '@mui/material';
import useAxios     from '../../hooks/useAxios';
import { useNavigate } from "react-router-dom";
import StateContext from '../../helpers/ContextState';
import useAsyncStorage   from '../../hooks/useAsyncStorage';


const storage = "register-user"

const Login=()=>{
 
  return  (
              <Grid container>                
                <Grid item xs={12}>
                  <Typography>
                    Proveedor de servicios
                  </Typography> 
                  <Typography sx={{mb:2}}>
                    Los servicios de Google los proporciona y se contratan con:
                  </Typography> 
                  <Typography>
                    Google LLC
                  </Typography> 
                  <Typography sx={{mb:2}}>
                    Empresa que se rige por las leyes del estado de Delaware (EE. UU.) y que opera bajo la legislación estadounidense
                  </Typography> 
                  <Typography>
                    1600 Amphitheatre Parkway
                  </Typography> 
                  <Typography>
                    Mountain View, California, 94043
                  </Typography> 
                  <Typography sx={{mb:2}}> 
                    EE. UU.
                  </Typography> 
                  <Typography>
                    Requisitos de edad mínima
                  </Typography> 
                  <Typography>
                    Si no tienes la edad mínima para poder gestionar tu propia cuenta de Google, debes contar con el permiso de tu padre, madre o tutor legal para utilizar una cuenta de Google. Pide a tu padre, madre o tutor legal que lea estos términos contigo.
                  </Typography> 
                </Grid>                
              </Grid>              
  )
}
export default Login
