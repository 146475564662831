import React from 'react';
import { useNavigate } from 'react-router-dom';
import useAxios from '../../hooks/useAxios';
import useAsyncStorage from '../../hooks/useAsyncStorage';
import {
  Grid,
  Button,
  Typography,
  Fade,
  Collapse,
} from '@mui/material';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import Container from './Container';



let navigate;
let storage;
let getInit;
let axios;
let user;
let userStorage;

const store         =   "chat";
const endpoint      =   "api/" + process.env.REACT_APP_BACKEND_VERSION+"/";
const mainColor     =   "#e8552a";







const ChatBotMessage = ({ data }) => {
    return (
      
                <Fade in={true}>
                    <Grid
                        sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        mb: 1,
                        }}
                    >
                        {data.route === 'welcome' && (
                        <Grid container>
                            <Grid item xs={9}>
                            <Typography variant="p" sx={{ fontSize: 13 }}>
                                {data.text}
                            </Typography>
                            </Grid>
                            <Grid item xs={3} align="center">
                            <SmartToyOutlinedIcon
                                sx={{
                                p: 1,
                                fontSize: 40,
                                borderRadius: 20,
                                border: 3,
                                color: mainColor,
                                }}
                            />
                            </Grid>
                        </Grid>
                        )}            
                    </Grid>
                    </Fade>
      
    );
  };




const List=({data, onClick})=>{
    return      <Fade in={true}>
                    
                    <Grid
                        sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        mb: 1,
                        }}
                    >
                        <Grid container spacing={1} justifyContent={'right'}>
                        {/* Commented out Grid item for illustration purposes */}
                        {/* <Grid item xs={2} align="center">
                            <SmartToyOutlinedIcon
                                sx={{ p: 1, fontSize: 20, borderRadius: 10, background: '#f2f2f2', color: mainColor }}
                            />
                            </Grid> */}
                        <Grid item xs={7}>
                            <Button variant="contained" fullWidth size="small" onClick={onClick}>
                                {data.text}
                            </Button>
                        </Grid>
                        </Grid>
                    </Grid>
                    
                </Fade>
            
}



const Mobile = () => {
    const [list, setList]           = React.useState([]);
    const [messages, setMessages]   = React.useState([]);
    const [loading, setLoading]     = React.useState(true);
    const axios                     = useAxios();
    const storage = useAsyncStorage(store);
    const userStorage = useAsyncStorage('user');
    const navigate = useNavigate();
  
    const getInit = async () => {
      const chatbotDialog = await storage.getDataFromStorage(store);
      user = userStorage.data ? userStorage.data : { name: 'Invitado' };
  
      if (!chatbotDialog) {
        const response = await axios.getData({}, `${endpoint}${store}`);
        let _messages = [];
  
        if (response?.data?.message) {
          _messages.push(response.data.message);
        }
  
        setMessages(_messages);
  
        if (response?.data?.list) {
          setTimeout(() => {
            let _messages_ = [];
            response.data.list.forEach((row) => {
              _messages_.push(row);
            });
            setLoading(false);
            setList(_messages_);
          }, 1500);
        }
      }
    };
  
    React.useEffect(() => {
      getInit();
    }, []);
  
    
  
    const UserMessage = ({ data }) => {
      return (
        <Grid
        
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            mb: 1,
          }}
        >
          1
        </Grid>
      );
    };

    const onClick=(data)=>{
        console.log(data)
    }
  
    return (
      <Container>
        <Collapse in={true}>
            <Grid sx={{ flex: 1, width: '100%', background: 'white', p: 2 }}>
            <Grid sx={{ flexDirection: 'column' }}>
                { 
                    messages.map((row, key) => {
                        return row.direction === 'bot' ? (
                            <ChatBotMessage key={key} data={row} />
                        ) : (
                            <UserMessage key={key} data={row} />
                        );
                    })
                }
            </Grid>
            {
                list&&list.length>0&&(
                    list.map((row,key)=>{
                        return <List onClick={onClick} key={key} data={row} />
                    })
                )
            }
            {loading && (
                <Grid>
                {/* Placeholder for loading indicator */}
                ...
                </Grid>
            )}
            </Grid>
            <Grid sx={{ flex: 0.2, width: '100%' }} align="center">
            <Grid
                sx={{
                background: mainColor,
                height: '100%',
                borderStartStartRadius: 45,
                borderStartEndRadius: 45,
                }}
            ></Grid>
            </Grid>
        </Collapse>
      </Container>
    );
  };
  
  export default Mobile;