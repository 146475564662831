import React from 'react';
import { Typography, Grid, Button, Paper } from '@mui/material';
import Container    from '../../../components/Theme/DashboardUsuario2';
import useAsyncStorage   from '../../../hooks/useAsyncStorage';
import { NavLink } from "react-router-dom";
import Directions from '../../../components/Directions';
import Resumen from '../../../components/Resumen';
import Map from '../../../components/GoogleMap/Map';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import Back from '../../../components/Button/Back';


const component                       =   'orden-servicios';
const step                            =   'step4';
const step2                           =   'step5';
const title                           =   'Agregar Dirección';


const Item=({row,num,handleCentrar,handleDelete})=>{
  return  <Paper sx={{p:2,mb:1}}>
            <Grid container>
              <Grid item xs={9}>
                <Typography sx={{fontSize:12}}>
                  {row.address_components}
                </Typography>
                <Typography sx={{fontSize:10}}>
                  Dirección <b>{row.direccion}</b> - Asistentes <b>{row.asistentes}</b>
                </Typography>                                      
              </Grid>
              <Grid item xs={3} align="right">
                <Button onClick={()=>{handleCentrar(row);}}>
                  <EditIcon sx={{fontSize:20}}/>
                </Button>
                <Button onClick={()=>{handleDelete(num)}}>
                  <ClearIcon sx={{fontSize:20}}/>
                </Button>
              </Grid>                                        
            </Grid>            
          </Paper>


}

const Home=()=>{
  
  const componentStorage              =   useAsyncStorage(component);
  const master                        =   useAsyncStorage("master");
  const [locations,setLocations]      =   React.useState([])
  const [inputs,setInputs]            =   React.useState({})
  const [centrar,setCentrar]          =   React.useState(false)
  const [reOrder,setReOrder]          =   React.useState(false)
  const [open,setOpen]                =   React.useState(false)
    
  const handleEvent=async(val)=>{
    try { 
      setReOrder(val)
      componentStorage.setDataToStorage(component,{...componentStorage.data,locations:[...val]})
    } catch (error) {
      console.error(error)
    }    
  }

  const handleCentrar=(row)=>{
    setCentrar(row);
    setOpen(row)
  }

  React.useEffect(()=>{
    componentStorage.getDataFromStorage(component).then((response)=>{
      if (response) {
        setInputs(response)
      }
      if (response.locations) {
        setLocations(response.locations);
      }
    })  
  },[])

  React.useEffect(()=>{
    if (locations.length>0) {
      handleEvent(locations)  
    }    
  },[])

  const handleAdd=()=>{
    //open,setOpen
    setOpen(open?false:true)
    //handleEvent(locations)

  }

  const handleDelete=(index)=>{
    // Crear una copia de la lista locations y eliminar el elemento en el índice especificado
    const updatedLocations = [...locations];
    updatedLocations.splice(index, 1);

    // Actualizar el estado locations con la nueva lista
    setLocations(updatedLocations);
    componentStorage.setDataToStorage(component,{...componentStorage.data,locations:updatedLocations})

    setReOrder(updatedLocations)
  }

  return  <Container >
            <Grid>
              <Typography variant='h3' className='text-secondary title-main' align="center">
                {title}
              </Typography>
              <Grid >
                <Grid container justifyContent={"center"} spacing={2}>
                  <Grid item xs={11} md={5}>
                      {
                        locations.length>0&&(
                          locations.map((row,key)=>{
                            return  <Item handleDelete={handleDelete} num={key} key={key} handleCentrar={handleCentrar}  row={row} />
                          })
                        )
                      }                      
                      <Directions setOpen={setOpen} 
                                  active={open} 
                                  handleEvent={handleEvent}
                                  defaultValue={open} 
                                  locations={locations} 
                                  setLocations={setLocations} 
                                  label="Ciudad" 
                                  contador={locations.length+1}/>
                      {
                        !open&&(
                          <Grid className='add-item' onClick={handleAdd}>
                            Agregar Dirección
                          </Grid>
                        )
                      }                      
                  </Grid>
                  <Grid item xs={11} md={5}>
                    {
                      locations&&
                      locations.length>1&&(
                        <Map setReOrder={setReOrder} reOrder={reOrder} centrar={centrar} locations={locations}/>
                      )
                    }                    

                    <Resumen component={<NavLink to={"../"+step}>Condiciones del transporte</NavLink>} inputs={inputs} label="Resumen del servicio" data={master.data}/>
                    <Grid sx={{mt:2}} align="center">
                      <Back/>
                      {
                        locations&&
                        locations.length>1&&(
                          
                            <Button variant={"contained"} component={NavLink} to={"../"+step2}> 
                              Continuar
                            </Button> 
                          
                        )
                      }   
                    </Grid>                 
                  </Grid>
                </Grid>
              </Grid>              
            </Grid>
          </Container>
}
export default Home
