import React from 'react';
import { Typography, Grid, Button } from '@mui/material';
import Container    from '../../../components/Theme/DashboardUsuario2';
import { NavLink } from "react-router-dom";
import imageSI from '../../../assets/png/rectanguloSI.png';
import imageNO from '../../../assets/png/rectanguloNO.png';

const step                            =   'step6';
const step2                           =   'step11';
const title                           =   'Deseas programar el servicio';
const title2                          =   'Si no lo deseas, se agendará tan pronto como sea posible';

const Home=()=>{
  
  
  
  return  <Container >
            <Grid>
              <Typography variant='h3' className='text-secondary title-main' align="center">
                {title}
              </Typography>
              <Grid >
                <Grid container justifyContent={"center"}>
                  <Grid item xs={11} md={7}>
                      <Grid sx={{mb:10}}>
                        <Typography sx={{mb:1.5,mt:2}} align='center'>
                          {title2}
                        </Typography>                          
                      </Grid>
                      <Grid sx={{mt:2}} align="center">
                          <Button component={NavLink} to={"../"+step} sx={{mb:2,}}> 
                            <img height={"60px"} src={imageSI} alt="programandoweb"/>                            
                          </Button> 
                          <Button  component={NavLink} to={"../"+step2} sx={{mb:2,}}> 
                            <img height={"60px"} src={imageNO} alt="programandoweb"/>                            
                          </Button> 
                      </Grid>
                  </Grid>                  
                </Grid>
              </Grid>              
            </Grid>
          </Container>
}
export default Home

