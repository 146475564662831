import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function BasicSelect({ 
                                      defaultValue,
                                      label,
                                      className, 
                                      data, 
                                      name, 
                                      onChange}) {
  const [age, setAge] = React.useState('');
  const [reset, setReset] = React.useState(false);

  const handleChange = (event) => {
    const result  =   data.find((search)=>search.id===event.target.value||parseInt(search.id)===parseInt(event.target.value))
    if (result) {
      setAge(result.id);  
    }
    if (name&&onChange) {
      onChange(name,result)
    }    
  };  

  React.useEffect(()=>{
    if (age===''&&defaultValue) {
      setAge(defaultValue);
      setReset(true)
      setTimeout(() => {
        setReset(false)
      }, 1000);
    }
  },[defaultValue])

  return (
    <Box sx={{mb:2}}>

        {
          label&&(
            <InputLabel >
              {label}
            </InputLabel>
          )
        }        
        {
          !reset&&(
            <Select
              defaultValue={defaultValue}
              fullWidth
              size='small'
              className={className}
              value={age}
              label={label}
              onChange={handleChange}
            > 
              {
                data&&data.length>0&&(
                  data.map((row,key)=>{
                    return  <MenuItem key={key} value={row.id}>
                              {row.name}
                            </MenuItem>
                  })
                )
              }
            </Select>
          )
        }
        
      
    </Box>
  );
}