import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function BasicSelect({ 
                                      defaultValue,
                                      label,
                                      className, 
                                      data, 
                                      name, 
                                      onChange
                                      }) {
  const [age, setAge]     = React.useState('');
  const [reset, setReset] = React.useState(false);

  const handleChange = (event) => {
    
    if (name&&onChange) {
      onChange(prevFormData => ({
        ...prevFormData,
        [event.target.name]: event.target.value,
      }));
      setAge(event.target.value);
    } 

  };  

  React.useEffect(()=>{
    if (age===''&&defaultValue) {
      setAge(defaultValue);
      setReset(true)
      setTimeout(() => {
        setReset(false)
      }, 1000);
    }
  },[defaultValue])
  
  return (
    <Box sx={{mb:2}}>
        {
          !reset&&(
            <Select
              name={name}
              defaultValue={defaultValue}
              fullWidth
              size='small'
              className={className}
              value={age}
              onChange={handleChange}
            > 
              <MenuItem value={null}>
                {label}
              </MenuItem>
              {
                data&&data.length>0&&(
                  data.map((row,key)=>{
                    return  <MenuItem key={key} value={row.value}>
                              {row.label}
                            </MenuItem>
                  })
                )
              }
            </Select>
          )
        }
        
      
    </Box>
  );
}