import { Button, Grid } from "@mui/material";
import { useState } from "react";
import PersonaNatural from './PersonaNatural';
import PersonaJuridica from './PersonaJuridica';

const DatosFacturacion=(props)=>{
    const [tipo,setTipo] = useState(false)
    return  <Grid>
                {
                    !tipo&&(
                        <Grid align="center">
                            Selecciona el tipo de Persona
                            <Grid >
                                <Button onClick={()=>setTipo(1)} variant="contained" sx={{mr:2}}>Natural</Button>
                                <Button onClick={()=>setTipo(2)} variant="contained">Jurídica</Button>
                            </Grid>
                        </Grid>
                    )
                }              
                {
                    tipo&&tipo===1&&(
                        <PersonaNatural {...props}/>
                    )
                } 
                {
                    tipo&&tipo===2&&(
                        <PersonaJuridica {...props}/>
                    )
                }  
            </Grid>
}
export default DatosFacturacion;