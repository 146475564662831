import React from 'react';
import { Typography, Grid, Button } from '@mui/material';
import Container    from '../../../components/Theme/DashboardUsuario2';
import { NavLink } from "react-router-dom";
import Back from '../../../components/Button/Back';


const step                            =   'step5';
const title                           =   'Condicones de transporte';

const Home=()=>{
  
  
  
  return  <Container >
            <Grid>
              <Typography variant='h3' className='text-secondary title-main' align="center">
                {title}
              </Typography>
              <Grid >
                <Grid container justifyContent={"center"} spacing={2}>
                  <Grid item xs={11} md={7}>
                      <Grid>
                        <Typography sx={{mb:1.5,mt:2}}>
                          El cliente entregará sus mercancías debidamente protegidas, rotuladas y empacadas, de tal manera que no constituyan peligro para otras mercancías, ni para nuestro personal. El cliente será responsable en todos los casos del contenido de las mercancías transportadas que han sido previamente empacadas y selladas por él, es decir, Global Trucker S.A.S, no responderá y queda exonerado por el transporte de mercancías en las que no le es posible su verificación. Cualquier anomalía, ilegalidad o ilicitud de la carga es responsabilidad exclusiva del cliente remitente.
                        </Typography>  
                        <Typography sx={{mb:1.5}}>
                          Asumimos todo riesgo de transporte por el valor costo de sus mercancías desde el momento del despacho. Dicho valor debe aparecer en nuestra orden de cargue o remisión de entrega (artículo 1031 y 1032 del Código de Comercio). En ningún caso indemnizaremos valores superiores al declarado. En caso de pérdida parcial se responderá por valor costo, este debes ser certificado por revisor fiscal y el número de unidades despachadas según nuestra remisión sin incluir I.V.A.
                        </Typography>  
                        <Typography sx={{mb:1.5}}>  
                          El remitente deberá aclarar en el momento de la entrega de la mercancía que ésta se encuentra debidamente legalizada ante las autoridades aduaneras - DIAN - y cumple con todas las disposiciones legales de nuestro país. En caso de retención y aprehensión, el transportador no se hará responsable de la liberación de la mercancía ni de los costos que su trámite conlleve, los cuales serán asumidos por el cliente.
                        </Typography>  
                        <Typography sx={{mb:1.5}}>  
                          El código de Comercio en el Art. 1028 exime a los transportadores, entregar las mercancías inventariadas. Cuando éstas no se han recibido de igual manera, excepto cuando la mercancía presenta deterioro de su empaque y/o rotura del mismo. 
                        </Typography>
                        <Typography sx={{mb:1.5}}>  
                          El Remitente está en la obligación de suministrar la información requerida en el artículo 1010 del Código De Comercio y por tanto, será responsable de los perjuicios por falta, omisión, inexactitud o insuficiencia
                        </Typography>
                        <Typography sx={{mb:1.5}}>  
                          La falta, inexactitud o insuficiencia de indicaciones por parte del remitente, tales como nombre, dirección del destinatario, el lugar de la entrega, la naturaleza, el valor, el número, el peso, el volumen, así como las condiciones especiales para él cargue, hará responsable al remitente ante el transportador y el destinatario de todos los perjuicios que ocurran por causa de lo anterior. 
                        </Typography>
                        <Typography sx={{mb:1.5}}>  
                          En el evento en que el destinatario devuelva las mercancías por razones ajenas al transportador, facturaremos los correspondientes fletes de retorno o de redistribución (logística inversa) según sea el caso. 
                        </Typography>
                        <Typography sx={{mb:1.5}}>  
                          Las indemnizaciones resultantes por pérdidas y/o daños parciales en el transporte de sus mercancías, se realizarán por el valor del costo de la mercancía antes de IVA, previa entrega por parte del cliente de la documentación requerida por la compañía de seguros y por nuestra empresa.
                        </Typography>
                        <Typography sx={{mb:1.5}}>  
                          No habrá cruces de indemnizaciones con cartera sin documentos que respalden esta transacción.
                        </Typography>
                        <Typography sx={{mb:1.5}}>  
                          Al momento de solicitar documentos para indemnización, el cliente cuenta con cinco (5) días hábiles para la presentación.
                        </Typography>
                        <Typography sx={{mb:1.5}}>  
                          Por norma de Código de Comercio, el valor declarado de la mercancía a transportar debe ser el valor costo de la misma y no su precio de venta.
                        </Typography>
                        <Typography sx={{mb:1.5}}>  
                          Una vez el destinatario haya firmado la remesa a conformidad, no aceptamos reclamaciones
                        </Typography>
                        <Typography sx={{mb:1.5}}>  
                          No transportamos alimentos perecederos, valores, ni mercancías peligrosas clase 1(Explosivos), 6.2 (Sustancias infecciosas) ni 7 (Materiales Radioactivos). 
                        </Typography>
                        <Typography sx={{mb:1.5}}>  
                          Si el cliente deja de despachar sus mercancías por un periodo superior a treinta (30) días, el acuerdo comercial se dará por terminado y las condiciones aquí establecidas quedarán sin vigencia.
                        </Typography>
                        <Typography sx={{mb:1.5}}>  
                          Forma parte integral del presente acuerdo comercial la <b>GUIA DE SERVICIO</b> que con el presente documento el cliente declara haber recibido y aceptado.
                        </Typography>
                        

                      </Grid>
                      <Grid sx={{mt:2}} align="center">
                          <Back/>
                          <Button variant={"contained"} component={NavLink} to={"../"+step}> 
                            Aceptar y continuar
                          </Button> 
                      </Grid>
                  </Grid>                  
                </Grid>
              </Grid>              
            </Grid>
          </Container>
}
export default Home
