import { useEffect, useState} from 'react';
import {Tabs,Tab,Paper,Box,Typography, Grid, Button} from '@mui/material';
import PropTypes from 'prop-types';
import useAsyncStorage from '../../hooks/useAsyncStorage';
import useAxios from '../../hooks/useAxios';
import image1 from '../../assets/png/avatar.png';
import image2 from '../../assets/png/avatar2.png';
import { NavLink } from 'react-router-dom';
import {formatCurrency,formatFechaLaravel} from '../../helpers/functions';
const CustomTabPanel=(props)=>{
  
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1, height:400, overflow:"hidden", overflowY:"scroll" }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const TabPanel=(props)=>{
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const  a11yProps=(index)=>{
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

let getInit;

export default function ColorTabs() {
  const {data}                  =   useAsyncStorage("master");
  const [value, setValue]       =   useState(0);
  const axios                   =   useAxios();
  const [data2, setData2]       =   useState({});
  

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  getInit=()=>{
    axios.getData().then((response)=>{
      //console.log(response.data)
      if(response&&response.data){
        const __data  = {
          Solicitado:response.data.Solicitado,
          Enviado:response.data.Enviado,
          Pagado:response.data.Pagado,
          Anulado:response.data.Anulado,
          "Sin Ruta disponible":response.data["Sin Ruta Disponible"],
        }
        setData2(__data)      
      }      
    })
  }

  useEffect(()=>{
    getInit();
  },[])

  const hanldeCancel=(row)=>{
    axios.postData(row).then((response)=>{
      getInit();      
    })    
  }

  return (
            <Box sx={{ width: '100%' }}>
              <Tabs
                value={value}
                centered
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="primary"
                aria-label="secondary tabs example"
              >
                {
                  
                  Object.entries(data2)&&Object.entries(data2).length>0&&(
                    Object.entries(data2).map((row,key)=>{
                      return <Tab key={key} label={row[0]} {...a11yProps(key)}/>
                    })                    
                  )
                }
              </Tabs>
              {
                  
                Object.entries(data2)&&Object.entries(data2).length>0&&(
                  Object.entries(data2).map((row,key)=>{
                    return  <CustomTabPanel key={key} value={value} index={key}>
                              <Grid container spacing={2} justifyContent={"center"}>
                                {
                                  row[1]&& row[1].length>0&&(
                                    row[1].map((row2,key2)=>{
                                      return  <Grid key={key2} item xs={11} md={6}>
                                                
                                                  <Paper  sx={{p:1}} > 
                                                    <Grid container spacing={2} component={NavLink} to={"/dashboard/servicios/detail/"+row2.id}>
                                                      <Grid item xs={3} align="center" >
                                                        <img className="img-fluid" src={row2&&row2.vehiculo&&row2.vehiculo.avatar_url||(key2 % 2 === 0?image1:image2)} alt="Programandoweb"/>
                                                        <Typography sx={{fontSize:12}}><b>{row2&&row2.vehiculo&&row2.vehiculo.label||"No deternimano"}</b></Typography>
                                                      </Grid>
                                                      <Grid item xs={9}>
                                                        <Grid>
                                                          <Typography component="h3" variant="h6">
                                                            #{row2.id||row2.cliente}
                                                          </Typography> 
                                                        </Grid>
                                                        <Grid>
                                                          <Typography sx={{fontSize:12}}>
                                                            Fecha: <b>{formatFechaLaravel(row2.created_at)}</b>
                                                          </Typography>                                                          
                                                        </Grid>
                                                        <Grid>
                                                          <Typography sx={{fontSize:12}}>
                                                            Origen: <b>{row2&&row2.direcciones2&&row2.direcciones2[0].formatted_address||"No deternimano"}</b>
                                                          </Typography>
                                                        </Grid>
                                                        <Grid>
                                                          <Typography sx={{fontSize:12}}>
                                                            Destino(s): 
                                                          </Typography>
                                                          {                                                          
                                                            <Grid>
                                                              {
                                                                row2&&row2.direcciones2&&row2.direcciones2.length>0&&(
                                                                  row2.direcciones2.map((row3,key3)=>{
                                                                    if (key3===0) {
                                                                      return;
                                                                    }
                                                                    return  <Grid key={key3}>
                                                                              <Typography sx={{fontSize:12}}>
                                                                                <b>{row3.formatted_address}</b>
                                                                              </Typography>                                                                        
                                                                            </Grid>
                                                                  })
                                                                )                                                          
                                                              }
                                                            </Grid>  
                                                          }
                                                        </Grid>
                                                        <Grid>
                                                          <Typography sx={{fontSize:12}}>
                                                          Valor: <b>
                                                                    {
                                                                      (formatCurrency(parseFloat(row2.transportation_value) +
                                                                                          parseFloat(row2.unloading_value) +
                                                                                          parseFloat(row2.declared_percentage) +
                                                                                          parseFloat(row2.loading_value))
                                                                            )==="$ NaN" ?
                                                                            '0,00' :
                                                                            formatCurrency(parseFloat(row2.transportation_value) +
                                                                                          parseFloat(row2.unloading_value) +
                                                                                          parseFloat(row2.declared_percentage) +
                                                                                          parseFloat(row2.loading_value))
                                                                    }
                                                                  </b>
                                                            
                                                          </Typography>
                                                        </Grid>
                                                        
                                                        
                                                      </Grid>
                                                    </Grid>
                                                    <Grid align="right">
                                                      {
                                                        row[0]==="Solicitado"&&(
                                                          <Button onClick={()=>hanldeCancel(row2)}>
                                                            Cancelar Servicio
                                                          </Button>
                                                        )
                                                      }
                                                      
                                                    </Grid>
                                                  </Paper>
                                                
                                              </Grid>
                                    })
                                  )
                                }
                              </Grid>                                                            
                            </CustomTabPanel> 
                  })                    
                )
              }              
            </Box>
  );
}