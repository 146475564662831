import { Typography, Grid, Button } from '@mui/material';
import Container    from '../../../components/Theme/DashboardUsuario2';
import useAsyncStorage   from '../../../hooks/useAsyncStorage';
import { NavLink } from "react-router-dom";
import React from 'react';
import  Date  from '../../../components/Input/Date';
import Resumen from '../../../components/Resumen';
import Back from '../../../components/Button/Back';

const component                       =   'orden-servicios';
const step                            =   'step8';
const title                           =   'Fechas de servicio';

const Home=()=>{
  
  
  const componentStorage              =   useAsyncStorage(component);
  const master                        =   useAsyncStorage("master");
  const [inputs,setInputs]            =   React.useState({})
  const [disabled,setDisabled]        =   React.useState(true)
  

  const handleEvent=async(_data)=>{
    try {   
      await componentStorage.setDataToStorage(component,_data)
      const result  = await componentStorage.getDataFromStorage(component)
      if (result.services_dates) {
        setDisabled(false)
      }else{
        setDisabled(true)
      }
    } catch (error) {
      console.error(error)
    }    
  }

  React.useEffect(()=>{
    
    if(componentStorage.data){
      setInputs(componentStorage.data)
    }  
    
    const verify=async()=>{
      const result  = await componentStorage.getDataFromStorage(component)
      if (result.services_dates) {
        setDisabled(false)
      }else{
        setDisabled(true)
      }
    }

    verify();

  },[master.data])
  
  return  <Container >
            <Grid>
              <Typography variant='h3' className='text-secondary title-main' align="center">
                {title}
              </Typography>
              <Grid >
                <Grid container justifyContent={"center"} spacing={2}>
                  <Grid item xs={11} md={5}>
                    <Grid sx={{p:2}}>
                      <Typography >
                        Selecciona la fecha para tu servicio. Si requieres más horarios, puedes hacerlos presionando el botón 'AGREGAR FECHA'. Ten en cuenta que por cada fecha, un servicio será creado.
                      </Typography>
                      <Date name="services_dates" handleEvent={handleEvent} inputs={inputs} setInputs={setInputs}/>                      
                    </Grid>
                    <Grid sx={{mt:2,p:2}}>
                      <Back/>
                      <Button disabled={disabled} variant={"contained"} component={NavLink} to={"../"+step}> 
                        Continuar
                      </Button> 
                    </Grid>
                  </Grid>
                  <Grid item xs={11} md={5}>
                    <Resumen inputs={inputs} label="Fechas de servicio" data={master.data}/>
                  </Grid>
                </Grid>
              </Grid>
              
            </Grid>
          </Container>
}
export default Home
