import Grid     from    '@mui/material/Grid';
import Button   from    '@mui/material/Button';
import useAxios  from '../../../hooks/useAxios';
import React, { useState } from 'react';
import Select from '../../../components/Select';

const Basic=({data,trasportadores,getInit})=>{
    const axios                     =   useAxios();
    const [inputs,setInputs]        =   useState({});
    const [transportador,setTranportador]        =   useState({});

    const onSubmit=(e)=>{
        e.preventDefault()
        axios.putData(inputs,'api/v1/dashboard/services').then((response)=>{
            if (response&&response.data&&response.data.id) {
                getInit(true)                
            }
        })                
    }

    React.useEffect(()=>{
        if (data) {
            setInputs(data)
        }
    },[data,setInputs])

    React.useEffect(()=>{
        if(trasportadores){
            setTranportador(trasportadores.find(search=>search.value===parseInt(inputs.transportador_id)))
        }                    
    },[inputs.transportador_id])
    
    
    
    return (
        <form onSubmit={onSubmit}>
                <Grid container spacing={2} >
                    <Grid item xs={12} md={3}>
                        <Select defaultValue={inputs.transportador_id} 
                                items={trasportadores||[]}
                                required 
                                onChange={setInputs} 
                                size="small" 
                                fullWidth 
                                name="transportador_id" 
                                type="text" 
                                label="Nombre"/>
                    </Grid>                    
                    <Grid item xs={12} md={9}>
                        {
                            transportador&&transportador.all&&(
                                <>
                                    <label className='label'>Datos del vehículo</label>
                                    <Grid container>
                                        <Grid item xs={12} md={4}>
                                            <label><b>Marca</b></label>
                                            <Grid>
                                                {
                                                    transportador.all.vehiculo_marca
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <label><b>Modelo</b></label>
                                            <Grid>
                                                {
                                                    transportador.all.vehiculo_modelo
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <label><b>Placa</b></label>
                                            <Grid>
                                                {
                                                    transportador.all.vehiculo_placa
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <label><b>Seguro Vencimiento</b></label>
                                            <Grid>
                                                {
                                                    transportador.all.vehiculo_seguro_vencimiento
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <label><b>Tecnomecánica</b></label>
                                            <Grid>
                                                {
                                                    transportador.all.vehiculo_tecnomecanica
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <label><b>Tecnomecánica Vencimiento</b></label>
                                            <Grid>
                                                {
                                                    transportador.all.vehiculo_tecnomecanica_vencimiento
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <label><b>Tipo de vehículo</b></label>
                                            <Grid>
                                                {
                                                    transportador.all.vehiculo_tipo_vehiculo
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {
                                        console.log(transportador)
                                    }
                                </>
                            )
                        }
                        
                    </Grid>                    
                </Grid>
                <Grid container justifyContent="right">
                    <Grid item xs={12} md={6} align="right" sx={{mt:2}}>
                        <Button variant="contained" type="submit">
                            Guardar
                        </Button>
                    </Grid>
                </Grid>            

        </form>
    )
}
export default Basic