import React from 'react';
import { Button, Grid } from '@mui/material';
import Input        from '../Input';

const DatosFacturacionNatural=({inputs,onSubmit, handleClose})=>{
    
    const [data, setData]           =   React.useState(inputs||{})
    const onChange=(e)=>{
        setData(prevData => ({ ...prevData, [e.target.name]: e.target.value }));
    }

    return      <Grid component="form" container justifyContent={"center"} spacing={1} onSubmit={(e)=>onSubmit(e,data)}>
                    <Grid item xs={12} md={6}>
                        <Input size={"small"} defaultValue={data.facturacion_razon_social} sx={{mb:2,width:"100%"}} required onChange={onChange}  fullWidth name="facturacion_razon_social" label="Razón Social"/>  
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input size={"small"} defaultValue={data.facturacion_nit} sx={{mb:2,width:"100%"}} required onChange={onChange}  fullWidth name="facturacion_nit" label="NIT"/>  
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input size={"small"} defaultValue={data.facturacion_name} sx={{mb:2,width:"100%"}} required onChange={onChange}  fullWidth name="facturacion_name" label="Nombre Rep. Legal"/>  
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input size={"small"} defaultValue={data.facturacion_surname} sx={{mb:2,width:"100%"}} required onChange={onChange}  fullWidth name="facturacion_surname" label="Apellido Rep. Legal"/>  
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input size={"small"} defaultValue={data.facturacion_cedula} sx={{mb:2,width:"100%"}} required onChange={onChange}  fullWidth name="facturacion_cedula" label="Cédula Rep. Legal"/>  
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input size={"small"} defaultValue={data.facturacion_direccion} sx={{mb:2,width:"100%"}} required onChange={onChange}  fullWidth name="facturacion_direccion" label="Dirección"/>  
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input size={"small"} defaultValue={data.facturacion_telefono} sx={{mb:2,width:"100%"}} required onChange={onChange}  fullWidth name="facturacion_telefono" label="Teléfono"/>  
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input size={"small"} defaultValue={data.facturacion_email} sx={{mb:2,width:"100%"}} required onChange={onChange}  fullWidth name="facturacion_email" type="email" label="Correo electrónico"/>  
                    </Grid>
                    <Grid item xs={12}  align="right">
                        {
                            handleClose&&(
                                <Button variant="outlined" onClick={handleClose} sx={{mr:1}}>
                                    Cerrar
                                </Button>
                            )
                        }
                        <Button variant="contained" type="submit">
                            Guardar
                        </Button>
                    </Grid>                    
                </Grid>
}
export default DatosFacturacionNatural;