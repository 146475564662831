import React from 'react';
import Container from '../../components/Theme/AuthForm';
import { Button, Grid, Typography } from '@mui/material';

import { NavLink } from "react-router-dom";

const Login=()=>{
  
  return  <Container >
              <Grid container spacing={2} justifyContent={"center"}>                
                <Grid item xs={12} md={7} align="center">
                  <Typography>
                    Estamos validando tu registro para habilitarla
                  </Typography> 
                  <Typography sx={{mb:2}}>
                    Te llegara un correo de verificación
                  </Typography>                   
                </Grid>
                <Grid item xs={12} md={7} align="center">
                  <Grid>
                    <Button component={NavLink} variant='contained' to="/auth/login" sx={{mb:1}}>
                      Volver
                    </Button>
                  </Grid>
                </Grid>
              </Grid>              
          </Container>
}
export default Login
