import Grid     from    '@mui/material/Grid';
import Button   from    '@mui/material/Button';
import Input    from    '../../Input';
import Select    from    '../../Select/Select6';
import useFormData  from '../../../hooks/useFormData';
import useAxios  from '../../../hooks/useAxios';
import useAsyncStorage  from '../../../hooks/useAsyncStorage';
import React from 'react';

const Basic=({data})=>{
    const store                                         =   useAsyncStorage("master");
    const { formData, onChange, setFormData }           =   useFormData();
    const axios =   useAxios();

    const onSubmit=(e)=>{
        e.preventDefault()

        if (data&&data.id===0) {
            axios.postData(formData).then((response)=>{
                if (response&&response.data&&response.data.id) {
                    //getInit(true)                
                }
            })   
        }else{
            axios.putData(formData).then((response)=>{
                if (response&&response.data&&response.data.id) {
                    //getInit(true)                
                }
            })
        }        
    }

    React.useEffect(()=>{
        if (data) {
            setFormData(data)
        }
    },[data,setFormData])

    return (
        <form onSubmit={onSubmit}>
              
            
                <Grid container spacing={2} >
                    <Grid item xs={12} md={2}>
                        <Input  defaultValue={data.vehiculo_placa} 
                                sx={{mb:0}}
                                onChange={onChange} 
                                size="small" 
                                fullWidth 
                                name="vehiculo_placa" 
                                type="text" 
                                label="Placas"/>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Input  defaultValue={data.vehiculo_marca} 
                                sx={{mb:0}}
                                onChange={onChange} 
                                size="small" 
                                fullWidth 
                                name="vehiculo_marca" 
                                type="text" 
                                label="Marca"/>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Input  defaultValue={data.vehiculo_modelo} 
                                sx={{mb:0}}
                                onChange={onChange} 
                                size="small" 
                                fullWidth 
                                name="vehiculo_modelo" 
                                type="text" 
                                label="Modelo"/>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Input  defaultValue={data.vehiculo_cilindraje} 
                                sx={{mb:0}}
                                onChange={onChange} 
                                size="small" 
                                fullWidth 
                                name="vehiculo_cilindraje" 
                                type="text" 
                                label="Cilindraje"/>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Select defaultValue={data.vehiculo_tipo_vehiculo} label="Tipo de Vehículo" data={store&&store.data&&store.data.vehiculo} name="vehiculo_tipo_vehiculo" onChange={setFormData} />                                      
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Input  defaultValue={data.vehiculo_seguro} 
                                sx={{mb:0}}
                                onChange={onChange} 
                                size="small" 
                                fullWidth 
                                name="vehiculo_seguro" 
                                type="text" 
                                label="Seguro"/>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Input  defaultValue={data.vehiculo_seguro_vencimiento} 
                                sx={{mb:0}}
                                onChange={onChange} 
                                size="small" 
                                fullWidth 
                                name="vehiculo_seguro_vencimiento" 
                                type="date" 
                                label="Seguro vencimiento"/>
                    </Grid>



                    <Grid item xs={12} md={3}>
                        <Input  defaultValue={data.vehiculo_tecnomecanica} 
                                sx={{mb:0}}
                                onChange={onChange} 
                                size="small" 
                                fullWidth 
                                name="vehiculo_tecnomecanica" 
                                type="text" 
                                label="Tecnomecánica"/>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Input  defaultValue={data.vehiculo_tecnomecanica_vencimiento} 
                                sx={{mb:0}}
                                onChange={onChange} 
                                size="small" 
                                fullWidth 
                                name="vehiculo_tecnomecanica_vencimiento" 
                                type="date" 
                                label="Tecnomecánica vencimiento"/>
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <Input  defaultValue={data.vehiculo_estudio_seguridad} 
                                sx={{mb:0}}
                                onChange={onChange} 
                                size="small" 
                                fullWidth 
                                name="vehiculo_estudio_seguridad" 
                                type="text" 
                                label="Estudio seguridad"/>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Input  defaultValue={data.vehiculo_estudio_seguridad_aprobacion} 
                                sx={{mb:0}}
                                onChange={onChange} 
                                size="small" 
                                fullWidth 
                                name="vehiculo_estudio_seguridad_aprobacion" 
                                type="date" 
                                label="Estudios vencimiento"/>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Input  defaultValue={data.vehiculo_estudio_seguridad_aprobacion_estatus} 
                                sx={{mb:0}}
                                onChange={onChange} 
                                size="small" 
                                fullWidth 
                                name="vehiculo_estudio_seguridad_aprobacion_estatus" 
                                type="text" 
                                label="Aprobación estatus"/>
                    </Grid>
                    


                </Grid>

                <Grid container justifyContent="right">
                    <Grid item xs={12} md={6} align="right" sx={{mt:2}}>
                        <Button variant="contained" type="submit">
                            Guardar
                        </Button>
                    </Grid>
                </Grid>            

        </form>
    )
}
export default Basic