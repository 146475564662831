import { Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import './index.css'
const Resumen=({label,data,inputs,component})=>{

    const [_data,_setData]          =   React.useState({...inputs,image:false})
    
    const [vehiculo,setVehiculo]    =   React.useState({})

    React.useEffect(()=>{

        let change  =   {...inputs};

        if (inputs.tipo_vehiculo_id) {
            
            const image     =   data.vehiculo.find(search=>search.value===inputs.tipo_vehiculo_id)
            if (image.images) {
                change= {   
                            image:image.images,
                            label:image.label
                        }
            }
            setVehiculo(image)
        }

        if (inputs.tipo_servicio_id) {
            const servicio     =   data.tipo_servicio_id.find(search=>search.value===inputs.tipo_servicio_id)
            if (servicio.label) {
                change={...change,servicio:servicio.label}
                
            }
        }

        if (inputs.modalidad_id) {
            const modalidad     =   data.modalidad_id.find(search=>search.value===inputs.modalidad_id)
            if (modalidad.label) {
                change={...change,modalidad:modalidad.label}
                
            }
        }

        /*

        if (inputs.tipo_carga_id) {
            const tipo_carga     =   data.tipo_carga_id.find(search=>search.value===inputs.tipo_carga_id)
            /*
            if (tipo_carga.label) {
                change={...change,tipo_carga:tipo_carga_id}
                
            }
          
        }
        */


        _setData(change)    
        

    },[inputs,data])

    

    return  <Paper sx={{p:2}}>

                <Grid>
                    <Typography variant="h6" align='center'>
                        {label}
                    </Typography>
                </Grid>                
                <Grid align="center">
                    <Grid className='resumen-image-background'>
                        
                            {
                                _data.image&&(<img src={_data.image} alt="programandoweb"/>)
                            }    
                        
                    </Grid>
                    <Grid>
                        <b>
                            {
                                _data.label&&(<Grid className='resumen-image-label'>{_data.label}</Grid>)
                            }  
                        </b>  
                    </Grid>    
                </Grid>
                <Grid align="left" >
                    {
                        /*
                            <Grid container spacing={1}>
                                <Grid item xs={4} >
                                    Servicio:
                                </Grid>
                                <Grid item xs={"auto"}>
                                    <b>{_data.servicio}</b>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={4} >
                                    Modalidad:
                                </Grid>
                                <Grid item xs={"auto"}>
                                    <b>{_data.modalidad}</b>
                                </Grid>
                            </Grid>    
                        * */
                    }
                    {
                        inputs.tipo_carga_id&&(
                            <Grid sx={{borderBottom:"solid 1px #f2f2f2",mb:1}}>
                                <Grid >
                                    Tipo de Vehículo:
                                </Grid>
                                <Grid >
                                    <b>{vehiculo.label}</b>
                                </Grid>
                            </Grid>
                        )
                    }
                    {
                        inputs.tipo_carga_id&&(
                            <Grid sx={{borderBottom:"solid 1px #f2f2f2",mb:1}}>
                                <Grid >
                                    Tipo de carga:
                                </Grid>
                                <Grid >
                                    <b>{inputs.tipo_carga_id}</b>
                                </Grid>
                            </Grid>
                        )
                    }
                    
                    {
                        /*
                        inputs.locations&&(
                            <Grid container spacing={1}>
                                <Grid item xs={4} >
                                    Direcciones:
                                </Grid>
                                <Grid item xs={"auto"}>
                                    {
                                        console.log(inputs)
                                    }
                                    <b>{inputs.locations.length}</b>
                                </Grid>
                            </Grid>
                        )
                        */
                    }

                    {
                        inputs.locations&&inputs.locations.length>0&&(
                            <Grid sx={{borderBottom:"solid 1px #f2f2f2",mb:1}}>
                                <Grid >
                                    Direcciones:
                                </Grid>
                                <Grid >
                                    
                                    {
                                        inputs.locations&&(
                                            inputs.locations.map((row,key)=>{
                                                return  <Grid key={key} sx={{mb:2}}>
                                                            <Grid><b>{(key+1)+ ")"+ row.address_components}</b></Grid>
                                                            <Grid>{row.direccion}</Grid>
                                                        </Grid>
                                            })
                                        )
                                    }
                                    
                                </Grid>
                            </Grid>
                        )
                    }

                    {
                        inputs.services_dates&&(
                            <Grid sx={{borderBottom:"solid 1px #f2f2f2",mb:1}}>
                                <Grid  >
                                    F. Programadas:
                                </Grid>
                                <Grid >
                                    <b>
                                        {
                                            inputs.services_dates&&(
                                                inputs.services_dates.map((row,key)=>{
                                                    return row + ((key+1)===inputs.services_dates.length?" ":", ");
                                                })
                                            )
                                        }
                                    </b>
                                </Grid>
                            </Grid>
                        )
                    }


                    {
                        inputs.merchandise_value&&(
                            <Grid sx={{borderBottom:"solid 1px #f2f2f2",mb:1}}>
                                <Grid >
                                    V. Declarado:
                                </Grid>
                                <Grid >
                                    <b>
                                        {
                                            inputs.merchandise_value&&(
                                                inputs.merchandise_value.map((row,key)=>{
                                                    return row + ((key+1)===inputs.merchandise_value.length?" ":", ");
                                                })
                                            )
                                        }
                                    </b>
                                </Grid>
                            </Grid>
                        )
                    }

                    {
                        inputs.merchandise_value&&(
                            <Grid sx={{borderBottom:"solid 1px #f2f2f2",mb:1}}>
                                <Grid >
                                    P. Declarado:
                                </Grid>
                                <Grid >
                                    <b>
                                        {
                                            inputs.merchandise_weight&&(
                                                inputs.merchandise_weight.map((row,key)=>{
                                                    return row + ((key+1)===inputs.merchandise_value.length?" ":", ");
                                                })
                                            )
                                        }
                                    </b>
                                </Grid>
                            </Grid>
                        )
                    }
                    {
                        inputs.merchandise_value&&(
                            <Grid sx={{borderBottom:"solid 1px #f2f2f2",mb:1, background:"#eee", px:1}}>
                                <Grid >
                                    Facturación:
                                </Grid>

                                {
                                    inputs.facturacion_name&&(
                                        <Grid >
                                            <Grid component={"label"}>Nombre y Apellidos: </Grid>
                                            <b>
                                                {
                                                    inputs.facturacion_name + " " + inputs.facturacion_surname
                                                }
                                            </b>
                                        </Grid>
                                    )
                                }

                                {
                                    inputs.facturacion_razon_social&&(
                                        <Grid >
                                            <Grid component={"label"}>Razón Social: </Grid>
                                            <b>
                                                {
                                                    inputs.facturacion_razon_social
                                                }
                                            </b>
                                        </Grid>
                                    )
                                }
                                
                                {
                                    inputs.facturacion_nit&&(
                                        <Grid >
                                            <Grid component={"label"}>NIT: </Grid>
                                            <b>
                                                {
                                                    inputs.facturacion_nit
                                                }
                                            </b>
                                        </Grid>
                                    )
                                }


                                {
                                    inputs.facturacion_direccion&&(
                                        <Grid >
                                            <Grid component={"label"}>Dirección: </Grid>
                                            <b>
                                                {
                                                    inputs.facturacion_direccion
                                                }
                                            </b>
                                        </Grid>
                                    )
                                }

{
                                    inputs.facturacion_telefono&&(
                                        <Grid >
                                            <Grid component={"label"}>Teléfono: </Grid>
                                            <b>
                                                {
                                                    inputs.facturacion_telefono
                                                }
                                            </b>
                                        </Grid>
                                    )
                                }
                                
                            </Grid>
                        )
                    }
                    

                </Grid>
                <Grid sx={{mt:1}}>
                    <Typography  align='left'>
                        {component}
                    </Typography>
                </Grid>                
            </Paper>
}
export default Resumen