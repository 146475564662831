import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import PostAddIcon from '@mui/icons-material/PostAdd';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import useAxios      from '../../hooks/useAxios';
import Input        from '../Input';
import Select        from '../Select';
import useFormData  from '../../hooks/useFormData';
import useAsyncStorage   from '../../hooks/useAsyncStorage';
import './index.css';
import { Button } from '@mui/material';

let file  =   false

export default function DocumentUploader({ onUpload , id , valor , fecha, base, placeholder }) {
    const { formData, onChange, setFormData }          =   useFormData();
    const axios                           =   useAxios();
    const [selectedFile, setSelectedFile] =   useState(null);
    const [uploading, setUploading]       =   useState(false);
    const [reset, setReset]               =   useState(false);
    const [loading, setLoading]           =   useState(false);
    const master                          =   useAsyncStorage("master");
    const [bancos, setBancos]             =   useState([]);
    const [tiposCuentas, setTiposCuentas] =   useState([]);
  
    const handleFileChange = (e) => {
      file = e.target.files[0];
      handleUpload(file)
      setSelectedFile(file)
    };

    const handleUpload = async (file) => {
      if (file) {
        try {
          setUploading(true);
          setSelectedFile(file);      
        } catch (error) {
          console.error('Error al subir el documento:', error);
        } finally {
          setSelectedFile(null);
          setUploading(false);
        }
      }
    };

  const handleSubmit=(e)=>{
      setLoading(true)
      e.preventDefault()
      axios.postDataAttachment({...formData,doc:file,url:id,base:base},'api/v1/dashboard/multimedia/uploads/documentos_usuarios').then((response)=>{
        if (response&&response.data&&response.data.action) {
          setLoading(false)
          onUpload(response.data.docs)
          setReset(true)
          setUploading(false);
          setSelectedFile(null);      
          setTimeout(() => {
            setReset(false)
          }, 1500);
          
        }
      return; 
    })
  }

  React.useEffect(()=>{
    if (bancos.length===0&&master.data&&master.data.bancos) {
      setBancos(master.data.bancos)
    }
    if (tiposCuentas.length===0&&master.data&&master.data.cuenta_tipo) {
      setTiposCuentas(master.data.cuenta_tipo)
    }
    
  },[master])


  return (
            <Grid  component={"form"} className='container-file' onSubmit={handleSubmit}>
                {
                  uploading&&(
                    <Grid align="center">
                      Subiendo la información y documento
                    </Grid>
                  )
                }
                {
                  reset&&(
                    <Grid align="center">
                      Depurando el sistema...
                    </Grid>
                  )
                }
                {
                  !reset&&(
                    <Grid align={"center"}>
                      {
                          selectedFile&&(
                              <Grid disabled={!selectedFile || uploading}>
                                  <Grid>
                                    <CloudUploadIcon sx={{
                                      fontSize:50,
                                      mt:2
                                    }} />      
                                  </Grid>
                                  Archivo listo para subir...
                              </Grid>
                          )
                      }   
                      <>
                          <Grid className="container-file-upload cursor-pointer">
                            {
                              !selectedFile&&(
                                <>
                                  <input
                                      type="file"
                                      accept=".pdf,.doc,.docx"
                                      onChange={handleFileChange}
                                      disabled={uploading}
                                  />
                                  <PostAddIcon sx={{
                                                    fontSize:80,
                                                    mt:1
                                                }}/>
                                </>
                              )
                            }                          
                            
                          </Grid>                                
                          <Grid 
                                style={{
                                  opacity: !selectedFile ? 0.5 : 1, // Reducir la opacidad cuando está desactivado
                                  pointerEvents: !selectedFile ? 'none' : 'auto', // Deshabilitar interacciones cuando está desactivado
                                }}
                          >
                            <Input sx={{mb:2,width:"100%"}} required onChange={onChange}  fullWidth name="name" type="text" label={"Número de cuenta"}/>                
                            <Grid sx={{mb:2}}>
                              <Select items={tiposCuentas} sx={{mb:2,width:"100%"}} required data={formData} onChange={setFormData}  fullWidth name="cuenta_tipo" type="number" label="Tipo de cuenta"/>                    
                            </Grid>
                            <Grid sx={{mb:2}}>
                              <Select items={bancos} sx={{mb:2,width:"100%"}} required data={formData} onChange={setFormData}  fullWidth name="cuenta_banco" type="number" label="Banco"/>                    
                            </Grid>
                            <Button disabled={loading} type="submit" variant="contained">Subir documento</Button>
                          </Grid>
                      </>                            
                    </Grid>                
                  )
                }                
            </Grid>
        );
}
