import  React from  'react';
import  Grid  from  '@mui/material/Grid';
import  Box  from  '@mui/material/Box';
import  './auth.css';
import { Typography } from '@mui/material';
import Header from '../../components/Header/Header2';

const   Container   =     React.forwardRef((props, ref) => {
    return  <Grid ref={ref} className="auth-form-register">
              <Header/>
              <Grid container justifyContent={"center"} sx={{pt:3}}>
                {
                  props.label&&(
                    <Grid item xs={10} sx={{mb:{xs:0,md:2}}}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography variant="h4">
                            {props.label}
                          </Typography>    
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                }                
                <Grid item xs={10}>
                  <Box component={props.onSubmit?"form":""} noValidate={props.noValidate||false} autoComplete="off" onSubmit={props.onSubmit}>
                    <Grid className={props.className}>
                      {props.children}
                    </Grid>
                  </Box>        
                </Grid>        
              </Grid>
            </Grid>
});


export default Container
//#fb19ff
