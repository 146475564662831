import { Grid, Paper } from "@mui/material";
import Container    from '../../Theme/Loading';
import Avatar from '../../Avatar/AvatarWithUpload';

const UserHomeServiciosDetalleServicio=({data})=>{
    return  <Container>
                {
                    data&&data.transportador&&data.transportador.avatar_url&&(
                        <Grid container justifyContent={"center"} sx={{py:5}}>
                            <Grid item xs={12} md={2} align="center">
                                <Avatar name="avatar_url" inputs={data.transportador}/>
                            </Grid>
                        </Grid>
                    )
                }
                
                <Grid container justifyContent={"center"} spacing={1}>
                    <Grid item xs={10} md={7} align="center">
                        <Grid component={"div"}><b>Tranportador asignado</b></Grid>
                        <Grid component={"label"}>
                            {data&&data.transportador&&data.transportador.name&&(
                                <Grid>
                                    {data.transportador.name} {data.transportador.surname}
                                </Grid>
                            )}
                        </Grid>                        
                    </Grid>                    
                    <Grid item xs={10} md={7} align="center">
                        <Grid component={"div"}><b>Número Celular del Tranportador</b></Grid>
                        <Grid component={"label"}>
                            {data&&data.transportador&&data.transportador.celular&&(
                                <Grid>
                                    {data.transportador.celular}
                                </Grid>
                            )}
                        </Grid>                        
                    </Grid> 
                    <Grid item xs={10} md={7} align="center">
                        <Grid component={"div"}><b>Placa</b></Grid>
                        <Grid component={"label"}>
                            AHS-43C
                        </Grid>                        
                    </Grid>                    
                </Grid>
            </Container>
}

export default UserHomeServiciosDetalleServicio;