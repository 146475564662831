import React, { useState } from 'react';
import Container from '../../components/Theme/AuthForm';
import Input from '../../components/Input/InputLabel';
import InputLabelPassword from '../../components/Input/InputLabelPassword';

import Select from '../../components/Select';
import useFormData  from '../../hooks/useFormData';
import {Paper, Button, Grid, Typography } from '@mui/material';
import Upload from '../../components/Cropper/UploadNew3';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from "react-router-dom";
import StateContext from '../../helpers/ContextState';
import useAsyncStorage   from '../../hooks/useAsyncStorage';
import useAxios     from '../../hooks/useAxios';
import Alert from '@mui/material/Alert';
import RegisterContract2 from './RegisterContract2';

const storage           =   "register-user"
const storage2          =   "master";
let userStorage         =   false;
const disabledTest      =   true;
const data_default_test =   {
  razon_social:"Prueba de razón social sas",
  nit:"Prueba de razón social sas",
  name:"Prueba",
  surname:"razón social sas",
  tipo_documento_representante_legal:15,
  documento_representante_legal:150000000,
  email:"elchhh@gmail.com",
  celular:"315000222",
  password:"password",
  password2:"password",  
}

const label = { inputProps: { 'aria-label': 'Acepto los términos' } };
const Login=()=>{
  const axios                   =   useAxios();
  const context                 =   React.useContext(StateContext)
  const navigate                =   useNavigate();
  userStorage                   =   useAsyncStorage(storage);
  const [accept,setAccept]      =   React.useState(false);
  const [data,setData]          =   useState(false)
  const { 
          formData,
          onChange,
          setFormData 
        } =   useFormData();

  const onSubmit = async (e)=>{
    e.preventDefault()

    console.log(5000)
    if (!formData.rut||!formData.camara_comercio||!formData.cedula) {
      
      return context.setOpenDialog({
        component:  <Paper sx={{p:2}}>
                      <Alert severity="warning">Es importante completar todos los documentos requeridos</Alert>
                      <Grid component={"ul"}>
                        {
                          !formData.rut&&(<Grid component={"li"}>
                            <Typography>
                              Es requerido el RUT
                            </Typography>
                          </Grid>)
                        }                        
                        {
                          !formData.camara_comercio&&(<Grid component={"li"}>
                            <Typography>
                              Es requerido cámara de comercio
                              </Typography>
                          </Grid>)
                        }                        
                        {
                          !formData.cedula&&(<Grid component={"li"}>
                            <Typography>
                              Es requerido la cédula
                            </Typography>
                            </Grid>)
                        }                        
                      </Grid>
                      <Grid align="right">
                        <Button variant="contained" onClick={()=>context.setOpenDialog(false)}>Cerrar</Button>
                      </Grid>
                    </Paper>
      })
      
    }

    if (formData.password!==formData.password2) {
      return context.setOpenDialog({
        component:  <Paper sx={{p:2}}>
                      <Alert severity="error">
                          Problemas de validación
                      </Alert>
                      <Grid component={"ul"}>                        
                            <Typography>
                              Las contraseñas no coinciden, por favor vuelve a intentarlo.
                            </Typography>   
                      </Grid>
                      <Grid align="right">
                        <Button variant="contained" onClick={()=>context.setOpenDialog(false)}>Cerrar</Button>
                      </Grid>
                    </Paper>
      })
    }
    

    context.setOpenDialog({
      loading:true
    })

    await axios.postData(formData).then((response)=>{
      if (response&&response.status==='success') {
        return navigate("/auth/register_response");
      }
    })

    context.setOpenDialog(false)
    
    return;
    
  }

  React.useEffect(()=>{
    setFormData(data_default_test)
    userStorage.getDataFromStorage(storage2).then((response)=>{
      setData(response)
    })    
  },[])

  const contract=()=>{

    context.setOpenDialog({
      component:  <Paper sx={{p:2}}>
                    <RegisterContract2/>
                    <Grid align="right">
                      <Button variant="contained" onClick={()=>{context.setOpenDialog(false)}}>Cerrar</Button>
                    </Grid>
                  </Paper>
    })
    
  }

  
  return  <Container label="Datos de registro"  onSubmit={onSubmit}>
    
              <Grid container spacing={2}>                
                <Grid item xs={12} md={6}>
                  <Input
                    variant="border"
                    sx={{mb:{md:1,xs:0},width:"100%"}} 
                    required={disabledTest} 
                    onChange={onChange}  
                    fullWidth 
                    name="razon_social" 
                    type="text" 
                    label="Razón social"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    variant="border"
                    sx={{mb:{md:1,xs:0},width:"100%"}} 
                    required={disabledTest} 
                    onChange={onChange}  
                    fullWidth 
                    name="nit" 
                    type="text" 
                    label="NIT"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    variant="border"
                    sx={{mb:{md:1,xs:0},width:"100%"}} 
                    required={disabledTest} 
                    onChange={onChange}  
                    fullWidth 
                    name="name" 
                    type="text" 
                    label="Nombres Representante Legal"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    variant="border"
                    sx={{mb:{md:1,xs:0},width:"100%"}} 
                    required={disabledTest} 
                    onChange={onChange}  
                    fullWidth 
                    name="surname" 
                    type="text" 
                    label="Apellidos Representante Legal"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Select
                    data={formData}
                    items={data&&data.tipo_documento?data.tipo_documento:[]}
                    variant="standard"
                    sx={{mb:{md:1,xs:0},width:"100%"}} 
                    required={disabledTest} 
                    onChange={setFormData}  
                    fullWidth 
                    name="tipo_documento_representante_legal" 
                    type="text" 
                    label="Tipo Documento Rep. Legal"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    variant="border"
                    sx={{mb:{md:1,xs:0},width:"100%"}} 
                    required={disabledTest} 
                    onChange={onChange}  
                    fullWidth 
                    name="documento_representante_legal" 
                    type="text" 
                    label="Nro Documento Rep. Legal"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    autoComplete={"off"}
                    variant="border"
                    sx={{mb:{md:1,xs:0},width:"100%"}} 
                    required={disabledTest} 
                    onChange={onChange}  
                    fullWidth 
                    name="email" 
                    type="email" 
                    label="Correo electrónico"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    autoComplete={"off"}
                    variant="border"
                    sx={{mb:{md:1,xs:0},width:"100%"}} 
                    required={disabledTest} 
                    onChange={onChange}  
                    fullWidth 
                    name="celular" 
                    type="text" 
                    label="Teléfono"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabelPassword
                    autoComplete={"off"}
                    variant="border"
                    sx={{mb:{md:1,xs:0},width:"100%"}} 
                    required={disabledTest} 
                    onChange={onChange}  
                    fullWidth 
                    name="password" 
                    type="password" 
                    label="Clave"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    variant="border"
                    sx={{mb:{md:1,xs:0},width:"100%"}} 
                    required={disabledTest} 
                    onChange={onChange}  
                    fullWidth 
                    name="password2" 
                    type="password" 
                    label="Verificar Clave"
                  />
                </Grid>
                <Grid item xs={12} >
                  <Typography variant="h6">
                    Documentos adjuntos
                  </Typography>  
                </Grid>
                <Grid item xs={12} md={4}>
                  <Upload context={context} onChange={setFormData} data={formData} name="camara_comercio" label="Cámara de comercio"/>                  
                </Grid>
                <Grid item xs={12} md={4}>
                  <Upload context={context} onChange={setFormData} data={formData} name="estado_financiero" label="Estado financiero"/>                  
                </Grid>
                <Grid item xs={12} md={4}>
                  <Upload context={context} onChange={setFormData} data={formData} name="certificacion_bancaria" label="Certificación bancaria"/>                  
                </Grid>
                <Grid item xs={12} md={4}>
                  <Upload context={context} onChange={setFormData} data={formData} name="rut" label="RUT"/>                  
                </Grid>
                <Grid item xs={12} md={4}>
                  <Upload context={context} onChange={setFormData} data={formData} name="camara_comercio" label="Cámara de comercio"/>                  
                </Grid>
                <Grid item xs={12} md={4}>
                  <Upload context={context} onChange={setFormData} data={formData} name="cedula" label="Cédula Rep. Legal"/>                  
                </Grid>

                <Grid item xs={12} align="right" sx={{mb:5}}>
                  <Typography className='cursor-pointer'>
                    <Grid component={"span"} 
                          className='cursor-pointer' onClick={contract}>¿Acepta los términos?</Grid> 
                    <Checkbox {...label} onClick={(e) => { setAccept(e.target.checked) ; contract();}} />
                  </Typography>                  
                  <Button variant='contained' type="submit" disabled={!accept?true:false}>
                    Crear Cuenta
                  </Button>
                </Grid>
              </Grid>              
          </Container>
}
export default Login
