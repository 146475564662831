import { Grid, Typography, Box } from '@mui/material';
import * as React from 'react';

export default function ControlledOpenSelect({ items, label, name, defaultValue, onChange }) {

  
  const handleOnChange=(e)=>{
    onChange(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));   
  }
  

  return  (
            <Grid sx={{position:"relative"}} className='select2023'>
              <Box>
                <Typography className='label'>
                  {label}
                </Typography>
              </Box>
              <select className='select2023' 
                defaultValue={defaultValue}
                name={name}
                onChange={handleOnChange}
                required
                >
                <option value={false}>
                  Seleccione
                </option> 
                { name &&
                  items &&
                  items.map((row, key) => {
                    return (
                      <option value={row.id || row.value || row.label || key} key={key}>
                        {row.label}
                      </option>              
                    );
                  })
                }
              </select>
            </Grid>
          );
}