import * as React from 'react';
import {Grid, Paper, Alert, Button, IconButton} from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
//import image from './index.png';
import Cropper from './Cropper'
import getCroppedImg from './cropImage'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import './index.css';
import Loading from '../Loading'
import useAxios      from '../../hooks/useAxios';
import AddIcon from '@mui/icons-material/Add';
import DescriptionIcon from '@mui/icons-material/Description';
import { Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';

const limit         = 2 * 1000000
//let imageResult   = image

export default function RecipeReviewCard({ onChange, data, name , aspect, label, context}) {
  const axios                     =   useAxios();
  const [reset, setReset]         =   React.useState(false);
  const [img, setImg]             =   React.useState(false);
  const [doc, setDoc]             =   React.useState(false);
  const [fail, setFail]           =   React.useState(false);
  const [inputs, setInputs]       =   React.useState(false);
  const [loading, setLoading]     =   React.useState(false);

  /*
  React.useEffect(()=>{
    imageResult   =   (data&&data.image)||image
  },[data])
  */
  
  React.useEffect(()=>{
    if (reset) {
      setReset(false)
    }
  },[reset])

  const handleCapture = (e) => {
    const fileDoc=e.files
    if (e.files) {
      
      if (e.files.length !== 0) {
        
        const file = e.files[0];

        if (file.size>limit) {
          return context.setOpenDialog({
                                          component:  <Paper sx={{p:2}}>
                                                        <Alert severity="warning">Es importante completar todos los documentos requeridos</Alert>
                                                        <Grid>El archivo que intentas subir pesa <b> {file.size/1000000}MB </b>, sobrepasa el máximo permitido <b> 2MB </b></Grid>
                                                        <Grid align="right">
                                                          <Button variant="contained" onClick={()=>context.setOpenDialog(false)}>Cerrar</Button>
                                                        </Grid>
                                                      </Paper>
                                        })
          
        }

        setLoading(true);

        //return console.log(file.size);
  
        // Verifica el tipo de archivo
        if (file.type.startsWith('image/') && limit<0) {
          // Es una imagen
          if (file.size >= limit) {
            // Colocar mensaje de error si el tamaño supera el límite
            setFail(true);
            setLoading(false);
          } else {
            // Lee la imagen
            let reader = new FileReader();
            reader.onload = function () {
              setLoading(false);
              setImg(reader.result);
            };
            reader.readAsDataURL(file);
          }
        } else {
          // No es una imagen, puedes manejar esto de acuerdo a tus necesidades

          

          axios.postDataAttachment({doc:fileDoc[0]},'api/v1/dashboard/multimedia/uploads_docs').then((response)=>{
            if (response&&response.data&&response.code==='SUCCESS') {
              setDoc(response.data)
              onChange(prevState => ({
                ...prevState,
                [name]: response.data
              }));              
            }           
          })

          setLoading(false);
          
        }
      }
    }
  };
  

  const onCropComplete=(croppedArea, croppedAreaPixels,image_)=>{
    let inputs_                     =   {...inputs};
        inputs_.croppedArea         =   croppedArea
        inputs_.croppedAreaPixels   =   croppedAreaPixels
        inputs_.image               =   image_
        if (setInputs) {
          setInputs(inputs_)  
        }        
  }

  const subCrop=()=>{
    setLoading(true)
    async function croping(){
      let croppedImage = await getCroppedImg(
                                              inputs.image,
                                              inputs.croppedAreaPixels
                                            )
      if (inputs&&setInputs) {
        let inputs_                   =   {...inputs};
            inputs_.image             =   croppedImage
            setInputs(inputs_)  
        
        axios.postData({images:croppedImage},'api/v1/dashboard/multimedia/uploads').then((response)=>{
          if (response&&response.data&&onChange&&data&&response.data.url) {
            let _data = {...data}
            if (!_data[name]) {
              _data[name]             = response.data.path
              _data[name+"_preview"]  = response.data.url
            }
            //_data[name]               = response.data
            //onChange(_data) 
            setDoc(response.data)           
          }
        })

      }
      
      if(onChange){
        //onChange(croppedImage)
        setReset(true)
      }
      
      //imageResult=croppedImage
      setImg(false)
      setLoading(false)
    }
    croping(this)
  }

  return (<div >
                  <Typography variant='h6'>
                      {label}
                  </Typography>
                  <Grid className='container-cropper'>
                    {fail||""}
                    {!reset&&!img?<>
                      
                      <Grid>
                        <Grid className="cam-content cursor-pointer">
                          <input  id='icon-button-file'
                                  type='file'
                                  capture='environment'
                                  onChange={(e) => handleCapture(e.target)}/>
                          {
                            data&&data[name+"_preview"]&&data[name+"_preview"]!==''&&(
                              <CardMedia
                                component="img"
                                sx={{height:"100%"}}
                                image={data[name+"_preview"]}
                                alt="Programandoweb"
                              />
                            )
                          }
                          
                            
                          <Grid className='photo-content'>
                            {!doc&&(<AddIcon sx={{fontSize:50}}/>)}                              
                            {doc&&(<DescriptionIcon sx={{fontSize:50}}/>)}                              
                          </Grid>
                            
                                                
                        </Grid>
                        <Grid align="center">
                          {
                            doc&&(
                              <IconButton component={NavLink} to={doc} target="_blank">
                                <FileDownloadIcon/>
                              </IconButton> 
                            )
                          }
                                                   
                        </Grid>
                      </Grid>
                    </>:false}
                    {
                      img&&!loading&&(<Cropper aspect={aspect||2.45/1} subCrop={subCrop} onCropComplete={onCropComplete} image={img}/>)
                    }
                    {
                      loading&&(  
                                  <Grid className='flex'>
                                    <Grid>
                                      <Loading/>
                                    </Grid>
                                  </Grid>
                                )
                    }
                  </Grid>  
            </div>
          );
}
