import { Button, Grid, Paper } from "@mui/material";
import {formatFechaLaravel} from '../../../helpers/functions'
import Table from "../../../components/Table/Table4";
import Table2 from "../../../components/Table/Table8";
import Container    from '../../Theme/Loading';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';


const UserHomeServiciosDetalleServicio=({data,novedades})=>{
    return  <Container>
                <Grid container justifyContent={"center"} spacing={1}>
                    {
                        data.status==='Sin Ruta Disponible'&&(
                            <Grid item xs={10} md={2} align="center">
                                <Button sx={{p:1}} variant="contained" href="https://api.whatsapp.com/send?phone=573124493543&text=Buenos%20d%C3%ADas,%20quiero%20solicitarles" target="_blank">
                                    <Grid>
                                        <WhatsAppIcon/>
                                    </Grid>
                                    <Grid>
                                        Solicitar ruta
                                    </Grid>                                    
                                </Button>
                            </Grid>
                        )
                    }                    
                    <Grid item xs={10} md={data.status==='Sin Ruta Disponible'?2:4}>
                        <Paper sx={{p:1}}>
                            <Grid component={"div"}><b>Código</b></Grid>
                            <Grid component={"label"}>
                                #{data.id}
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={10} md={4}>
                        <Paper sx={{p:1}}>
                            <Grid component={"div"}><b>Fecha</b></Grid>
                            <Grid component={"label"}>
                                {formatFechaLaravel(data.created_at)}
                            </Grid>
                        </Paper>    
                    </Grid>
                    <Grid item xs={10} md={4}>
                        <Paper sx={{p:1}}>
                            <Grid component={"div"}><b>Estado Servicio</b></Grid>
                            <Grid component={"label"}>
                                {data.status}
                            </Grid>
                        </Paper>    
                    </Grid> 

                    {
                        /*
                            <Grid item xs={10} md={3}>
                                <Paper sx={{p:1}}>
                                    <Grid component={"div"}><b>Modalidad</b></Grid>
                                    <Grid component={"label"}>
                                        {data.modalidad}
                                    </Grid>
                                </Paper>
                            </Grid>    
                        * */
                    }
                    
                    <Grid item xs={10} md={4}>
                        <Paper sx={{p:1}}>
                            <Grid component={"div"}><b>Tipo de Carga</b></Grid>
                            <Grid component={"label"}>
                                {data.tipo_carga}
                            </Grid>
                        </Paper>    
                    </Grid>
                    <Grid item xs={10} md={4}>
                        <Paper sx={{p:1}}>
                            <Grid component={"div"}><b>Tipo de Servicio</b></Grid>
                            <Grid component={"label"}>
                                {data.tipo_servicio}
                            </Grid>
                        </Paper>    
                    </Grid>
                    <Grid item xs={10} md={4}>
                        <Paper sx={{p:1}}>
                            <Grid component={"div"}><b>Tipo de Vehículo</b></Grid>
                            <Grid component={"label"}>
                                {data.tipo_vehiculo}
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        {
                            data&&data.direcciones&&(
                                <Table data={data.direcciones}/>                                
                            )
                        }                        
                    </Grid>

                    <Grid item xs={12}>
                        {
                            data&&(
                                <Table2 data={data} novedades={novedades}/>                                
                            )
                        }                        
                    </Grid>

                    
                    
                </Grid>
            </Container>
}

export default UserHomeServiciosDetalleServicio;