import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import {Box,Grid, IconButton} from '@mui/material';
import useAxios      from '../../hooks/useAxios';
import useAsyncStorage from '../../hooks/useAsyncStorage';
import DatosBasicos from './Components/DatosBasicos';
import DatosFacturacion from '../DatosFacturacion';
import StateContext from '../../helpers/ContextState';
import EditIcon from '@mui/icons-material/Edit';

const keys  =   "master";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1, height:400, overflow:"hidden", overflowY:"scroll" }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

let getInit=false

export default function BasicTabs() {
  const context                     =   React.useContext(StateContext);
  const {getDataFromStorage}        =   useAsyncStorage(keys);
  const axios                       =   useAxios();
  const [value, setValue]           =   React.useState(0);
  const [data, setData]             =   React.useState({});
  const [inputs,setInputs]          =   React.useState({})
  const user                        =   useAsyncStorage("user");
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const BACKEND = "api/" + process.env.REACT_APP_BACKEND_VERSION;

  getInit=()=>{
    axios.getData({},BACKEND+"/dashboard/user-profile").then((response)=>{
      if (response&&response.data) {
        setData(response.data)
        setInputs(response.data)
      }    
    })
  }

  React.useEffect(()=>{
    getInit();
    getDataFromStorage(keys).then((response)=>{
      if (response) {
        
      }      
    })
  },[])

  const onSubmitStore=async(e,data)=>{
    e.preventDefault();
    let data_prev =   user.data;
    
    await axios.postData(data,"api/v1/dashboard/billing").then((response)=>{
      if (response&&response.data) {        
        user.setDataToStorage("user",{...data_prev,...response.data})      
        user.setDataToStorage("user2",{...data_prev,...response.data})      
      }
      getInit();
    })

    context.setOpenDialog(false)

  }

  

  const onSubmit=(e)=>{
      e.preventDefault()
      axios.postDataAttachment(data,BACKEND+"/dashboard/user-profile").then((response)=>{
          if (response&&response.data&&response.data.id) {
            getInit(true)                
          }
      })
  }

  const modal=()=>{
    context.setOpenDialog({
      component:  <Grid sx={{p:4}}>
                    <DatosFacturacion inputs={inputs} onSubmit={onSubmitStore} setData={setInputs} handleClose={()=>context.setOpenDialog(false)}  />                    
                  </Grid>
    })
  }
  
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{justifyContent: 'center'}}>
          <Tab label="Datos" {...a11yProps(0)} />          
          <Tab label="Facturación" {...a11yProps(1)} />          
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <DatosBasicos data={data} setData={setData} onSubmit={onSubmit} />
      </CustomTabPanel>      
      <CustomTabPanel value={value} index={1}>
        <Grid >
          <Grid align="right">
            <IconButton onClick={modal}><EditIcon/></IconButton>
          </Grid>

          {
            data.facturacion_razon_social&&data.facturacion_razon_social!==''&&(
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Typography component={"div"}>
                    Razón Social                
                  </Typography>   
                </Grid>  
                <Grid item xs={12} md={6}>
                  <Typography component={"div"}>
                    <b>{data.facturacion_razon_social}</b>                
                  </Typography>   
                </Grid>  
              </Grid>
            )
          }
          
          {
            data.facturacion_nit&&(
              <Grid container>
                <Grid xs={12} md={6}>
                  <Typography component={"div"}>
                    NIT 
                  </Typography>   
                </Grid>
                <Grid xs={12} md={6}>
                  <Typography component={"div"}>
                    <Grid>
                      <b>{data.facturacion_nit}</b>
                    </Grid>
                    <Grid>
                      Cédula Rep. Legal <b>{data.facturacion_cedula}</b>
                    </Grid>
                  </Typography>   
                </Grid>

              </Grid>
            )
          }


          {
            data.facturacion_nit===''&&(
              <Grid container>
                <Grid xs={12} md={6}>
                  <Typography component={"div"}>
                    Cédula 
                  </Typography>   
                </Grid>
                <Grid xs={12} md={6}>
                  <Typography component={"div"}>
                    <b>{data.facturacion_cedula}</b>                    
                  </Typography>   
                </Grid>
              </Grid>
            )
          }
          

          {
            data.facturacion_razon_social&&(
              <Grid container>
                <Grid xs={12} md={6}>
                  <Typography component={"div"}>
                    Razón social 
                  </Typography>   
                </Grid>
                <Grid xs={12} md={6}>
                  <Typography component={"div"}>
                    <Grid>
                      <b> {(data.facturacion_razon_social)||"Edita los datos"} </b>
                    </Grid>                    
                  </Typography>   
                </Grid>

              </Grid>
            )
          }
          
          
          <Grid>
            <Grid container>
              <Grid xs={12} md={6}>
                <Typography component={"div"}>
                  Nombres y Apellidos
                </Typography>   
              </Grid>
              <Grid xs={12} md={6}>
                <Typography component={"div"}>
                <b> {(data.facturacion_name+ " " + data.facturacion_surname)||"Edita los datos"} </b>
                </Typography>   
              </Grid>
            </Grid>            
          </Grid>

          <Grid>
            <Grid container>
              <Grid xs={12} md={6}>
                <Typography component={"div"}>
                  Dirección
                </Typography>   
              </Grid>
              <Grid xs={12} md={6}>
                <Typography component={"div"}>
                  <b> {(data.facturacion_direccion)||"Edita los datos"} </b>
                </Typography>   
              </Grid>
            </Grid>            
          </Grid>

          <Grid>
            <Grid container>
              <Grid xs={12} md={6}>
                <Typography component={"div"}>
                  Correo
                </Typography>   
              </Grid>
              <Grid xs={12} md={6}>
                <Typography component={"div"}>
                <b>{data.facturacion_email||"Edita los datos"}</b>
                </Typography>   
              </Grid>
            </Grid>            
          </Grid>

          <Grid>
            <Grid container>
              <Grid xs={12} md={6}>
                <Typography component={"div"}>
                  Teléfono
                </Typography>   
              </Grid>
              <Grid xs={12} md={6}>
                <Typography component={"div"}>
                <b>{data.facturacion_telefono||"Edita los datos"}</b>
                </Typography>   
              </Grid>
            </Grid>            
          </Grid>          
        </Grid> 
      </CustomTabPanel>      
    </Box>
  );
}
