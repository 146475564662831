import  React             from  'react';
import  Grid              from  '@mui/material/Grid';
import  logo from '../../assets/png/logo-transparent-home.png';
const   Container   =     React.forwardRef((props, ref) => {
  return  <Grid ref={ref} sx={{height:"100vh", display: 'flex',flexDirection: 'column'}}>
            <Grid align="center">
              <Grid component={"img"} src={logo} alt="Programandoweb" sx={{mt:2}}/>              
            </Grid>
            {props.children}
          </Grid>
});


export default Container
