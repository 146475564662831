import { Grid } from "@mui/material"
import './Menu.css'
import  Avatar  from  '../Avatar';
import  logo  from  '../../assets/png/logo192.png';
import MenuItem from '@mui/material/MenuItem';
import { NavLink } from "react-router-dom";


const Menu=({items})=>{
    return      <Grid className="content-menu">
                    <Grid align="center" sx={{pt:{md:2,xs:0.5},pb:{md:2,xs:0.5}}}>
                        <Avatar sx={{width:{md:"60%",xs:"20%"},height:"auto"}} 
                                title={process.env.REACT_APP_NAME} 
                                alt={process.env.REACT_APP_NAME} 
                                src={logo}
                        />
                    </Grid>
                    {
                        items&&items.length>0&&(
                            items.map((row,key)=>{
                                if (!row.menu) {
                                    return false
                                }
                                return  <Grid className={row.className||"normal"} key={key}>
                                            <MenuItem component={NavLink} to={row.url||row.path} key={key} >                                            
                                                {row.name}
                                            </MenuItem>
                                        </Grid>
                            })
                        )
                    }     
                </Grid>
}
export default Menu