import { Typography, Grid, Button } from '@mui/material';
import Container    from '../../../components/Theme/DashboardUsuario2';
import useAsyncStorage   from '../../../hooks/useAsyncStorage';
import { NavLink } from "react-router-dom";
import React from 'react';
import Resumen from '../../../components/Resumen';
import Textarea from '../../../components/Input/Textarea';
import useFormData  from '../../../hooks/useFormData';
import Back from '../../../components/Button/Back';

const component                       =   'orden-servicios';
const step                            =   'step10';
const title                           =   'Sugerencias';

const Home=()=>{
  const { formData, onChange }        =   useFormData();
  
  const componentStorage              =   useAsyncStorage(component);
  const master                        =   useAsyncStorage("master");
  const [inputs,setInputs]            =   React.useState({})
  

  

  const handleEvent=async()=>{
    try {     
      await componentStorage.setDataToStorage(component,{...inputs,...formData})
    } catch (error) {
      console.error(error)
    }    
  }

  React.useEffect(()=>{
    
    if(componentStorage.data){
      setInputs(componentStorage.data)
    }   

  },[master.data])
  

  
  return  <Container >
            <Grid>
              <Typography variant='h3' className='text-secondary title-main' align="center">
                {title}
              </Typography>
              <Grid >
                <Grid container justifyContent={"center"} spacing={2}>
                  <Grid item xs={11} md={5}>
                    <Grid sx={{p:2}}>
                      <Typography >
                        <b>Describe la mercancía</b>
                      </Typography>
                      <Typography sx={{mb:1}}>
                        Recuerda que <b>NO</b> se pueden transportar: armas, explosivos, estupefacientes, sustancias químicas.
                      </Typography>   
                      <Textarea name={"merchandise_descripction"}
                                label="Descripción de la mercancía"
                                defaultValue={componentStorage.data&&componentStorage.data.merchandise_descripction?componentStorage.data.merchandise_descripction:""}
                                fullWidth
                                onChange={onChange}
                      />                   
                    </Grid>                    
                    <Grid sx={{p:2}}>
                      <Typography >
                        <b>Condiciones del transporte</b>
                      </Typography>
                      <Typography sx={{mb:1}}>
                        Describe algún requisito especial para el transporte de la mercancías
                      </Typography>   
                      <Textarea name={"merchandise_especial_descripction"}
                                label="Descripción especial de transporte de la mercancía"
                                defaultValue={componentStorage.data&&componentStorage.data.merchandise_especial_descripction?componentStorage.data.merchandise_especial_descripction:""}
                                fullWidth
                                onChange={onChange}
                      />                   
                    </Grid>
                    <Grid sx={{mt:2,p:2}}>
                      <Back/>
                      <Button onClick={handleEvent} variant={"contained"} component={NavLink} to={"../"+step}> 
                        Guardar y Continuar
                      </Button> 
                    </Grid>                    
                  </Grid>
                  <Grid item xs={11} md={5}>
                    <Resumen inputs={inputs} label="Fechas de servicio" data={master.data}/>
                  </Grid>
                </Grid>
              </Grid>              
            </Grid>
          </Container>
}
export default Home
