import { Typography, Grid, Button, IconButton } from '@mui/material';
import Container    from '../../../components/Theme/DashboardUsuario2';
import useAsyncStorage   from '../../../hooks/useAsyncStorage';
import React from 'react';
import Resumen from '../../../components/Resumen';
import useAxios     from '../../../hooks/useAxios';
import StateContext from '../../../helpers/ContextState';
import { useNavigate } from "react-router-dom";
import Back from '../../../components/Button/Back';
import EditIcon from '@mui/icons-material/Edit';
import DatosFacturacion from '../../../components/DatosFacturacion';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const component                       =   'orden-servicios';
const title                           =   'Facturación';
let componentStorage                  =   false;


const Home=()=>{
  const context                       =   React.useContext(StateContext);
  const navigate                      =   useNavigate();
  const {postData}                    =   useAxios();  
  const master                        =   useAsyncStorage("master");
  const user                          =   useAsyncStorage("user");
  const [inputs,setInputs]            =   React.useState({})
  componentStorage                    =   useAsyncStorage(component);

  

  React.useEffect(()=>{

    if(componentStorage.data){
      setInputs(componentStorage.data)
    }   

  },[master.data])


  React.useEffect(()=>{
    
    if (user.data) {
      setInputs((prevInputs) => ({ ...prevInputs, ...user.data, ...componentStorage.data }));
    } 

  },[user.data])
  
  const onSubmit= async(e)=>{
    e.preventDefault();
    
    //return console.log(inputs)
    context.setOpenBackdrop("modal")


    await postData(inputs).then((response)=>{
      context.setOpenBackdrop(false)
      try {

        if (response&&response.data&&response.data&&!response.data.success) {
          context.setOpenDialog(
            {
              maxWidth:"xs",
              component:  <Grid align="center" sx={{p:2}}>
                            <Grid >
                              <ErrorOutlineIcon sx={{fontSize:50}}/>
                            </Grid>
                            <Typography component={"h3"}>
                              Tu solicitud se procesó pero no hay servicios disponibles para esa ubicación.
                            </Typography>
                          </Grid>
            }
          )
        }

        //console.log(response.data.success);return ;

        if (response.data&&response.data.id) {
          context.setOpenMessage("Orden creada con éxito")
          //componentStorage.removeData()
          return navigate("/dashboard/servicios");
        }else{
          context.setOpenMessage("Problemas al crear su orden de servicio, contacte al administrador")
        }       
      } catch (error) {
        console.error(error)
      }      
    })         
  }

  const onSubmitStore=async(e,data)=>{
    e.preventDefault();
    let data_prev =   user.data;
    
    await postData(data,"api/v1/dashboard/billing").then((response)=>{
      console.log(response.data)
      if (response&&response.data) {
        
        user.setDataToStorage("user",{...data_prev,...response.data})      
        user.setDataToStorage("user2",{...data_prev,...response.data})      
      }
      
    })

    context.setOpenDialog(false)

  }

  
  //console.log(user)

  const modal=()=>{
    context.setOpenDialog({
      component:  <Grid sx={{p:4}}>
                    <DatosFacturacion inputs={inputs} onSubmit={onSubmitStore} setData={setInputs} handleClose={()=>context.setOpenDialog(false)}  />                    
                  </Grid>
    })
  }
  
  return  <Container >
            <Grid component={"form"} onSubmit={onSubmit} className='primer-form'>
              <Typography variant='h3' className='text-secondary title-main' align="center">
                {title}
              </Typography>
              <Grid >
                <Grid container justifyContent={"center"} spacing={2}>
                  <Grid item xs={11} md={5}>
                    <Grid sx={{p:2}}>
                      <Grid container>
                        <Grid item xs={12} md={10}>
                          <Typography component={"div"}>
                            <b>Datos de facturación</b>
                          </Typography>                          
                        </Grid>
                        <Grid  item xs={"auto"}>
                          <Grid align="right">
                            <IconButton onClick={modal}><EditIcon/></IconButton>
                          </Grid> 
                        </Grid>
                      </Grid>

                      {
                          !inputs.facturacion_nit&&(
                            <>
                              <Grid>
                                <Typography component={"div"}>
                                  <Grid>
                                    Cédula Nro <b>{inputs.facturacion_cedula||"Edita los datos"}</b>
                                  </Grid>
                                </Typography>   
                              </Grid>
                              <Grid>
                                <Typography component={"div"}>
                                  {
                                    inputs.razon_social!==''&&(
                                      <Grid>
                                        Nombres y Apellidos: 
                                        <Grid>
                                          <b> {(inputs.facturacion_name+ " " + inputs.facturacion_surname)||"Edita los datos"} </b>
                                        </Grid> 
                                      </Grid>
                                    )
                                  }
                                </Typography>   
                              </Grid>
                              <Grid>
                                <Typography component={"div"}>
                                  {
                                    inputs.facturacion_email&&(
                                      <>
                                      Correo:
                                        <Grid>
                                          <b>{inputs.facturacion_email||"Edita los datos"}</b>
                                        </Grid>
                                      </>
                                    )
                                  }                          
                                  <Grid>
                                    Teléfono:
                                    <Grid>
                                      <b>{inputs.facturacion_telefono||"Edita los datos"}</b>
                                    </Grid>
                                  </Grid>
                                </Typography> 
                              </Grid>
                            </>
                          )
                      }
                      {
                          inputs.facturacion_nit&&inputs.facturacion_nit!==null&&(
                            <>
                              <Grid>
                                <Typography component={"div"}>
                                  Documento 
                                  <Grid>
                                    NIT <b>{inputs.facturacion_nit}</b>
                                  </Grid>
                                  <Grid>
                                    Cédula Rep. Legal <b>{inputs.facturacion_cedula}</b>
                                  </Grid>
                                </Typography>   
                              </Grid>
                              <Grid>
                                <Typography component={"div"}>
                                  {
                                    inputs.razon_social!==''&&(
                                      <Grid>
                                        Razón social: 
                                        <Grid>
                                          <b> {(inputs.facturacion_razon_social)||"Edita los datos"} </b>
                                        </Grid> 
                                      </Grid>
                                    )
                                  }
                                </Typography>   
                              </Grid>
                              <Grid>
                                <Typography component={"div"}>
                                  {
                                    inputs.razon_social!==''&&(
                                      <Grid>
                                        Nombres y Apellidos Rep Legal: 
                                        <Grid>
                                          <b> {(inputs.facturacion_name+ " " + inputs.facturacion_surname)||"Edita los datos"} </b>
                                        </Grid> 
                                      </Grid>
                                    )
                                  }
                                </Typography>   
                              </Grid>
                              <Grid>
                                <Typography component={"div"}>
                                  {
                                    inputs.facturacion_email&&(
                                      <>
                                      Correo:
                                        <Grid>
                                          <b>{inputs.facturacion_email||"Edita los datos"}</b>
                                        </Grid>
                                      </>
                                    )
                                  }                          
                                  <Grid>
                                    Teléfono:
                                    <Grid>
                                      <b>{inputs.facturacion_telefono||"Edita los datos"}</b>
                                    </Grid>
                                  </Grid>
                                </Typography> 
                              </Grid>
                            </>  
                          )
                      }
                    </Grid>  
                    <Grid sx={{mt:2,p:2}}>
                      <Back/>
                      <Button disabled={inputs.facturacion_nit===''&&inputs.facturacion_cedula===''} variant={"contained"} type="submit"> 
                        Generar Servicio
                      </Button> 
                    </Grid>             
                  </Grid>
                  <Grid item xs={11} md={5}>
                    <Resumen inputs={inputs} label="Resumen" data={master.data}/>
                  </Grid>
                </Grid>
              </Grid>
              
            </Grid>
          </Container>
}
export default Home
