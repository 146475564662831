import Container    from '../../../components/Theme/Dashboard';
import Table    from '../../../components/Table';
import View from './View';
import usePermissions from '../../../hooks/usePermissions';


const td  = [
  {
    label:"Servicio",
    field:"id",
    className:"",
    align:"left",
  },
  {
    label:"Cliente",
    field:"cliente",
    className:"",
    align:"left",
  },
  {
    label:"Transportador",
    field:"transportador.name",
    className:"",
    align:"left",
  },
  /*
  {
    label:"Dirección",
    field:"direccion",
    className:"",
    align:"left",
  },
  */
  {
    label:"Tipo de carga",
    field:"tipo_carga",
    className:"",
    align:"center",
  },
  {
    label:"Tipo Veh.",
    field:"tipo_vehiculo",
    className:"",
    align:"center",
  },
  {
    label:"Acción",
    field:"event",
    className:"",
    align:"center",
    event:[
      {
        label:"Ver",
        action:"view",
        component:View,
      },
    ]
  },
]

const Home=(props)=>{
  const permissions     =   usePermissions("clientes","index",{
                                                                component:Table,
                                                                td:td,
                                                                skipAdd:true,
                                                                fullScreenModal:true,
                                                                download:true,
                                                                filterStatus:"status_services"
                                                              });
  return  <Container>
            {permissions.render()}
          </Container>
}
export default Home
