import * as React from 'react';
import TextField from '@mui/material/TextField';

export default function MultilineTextFields({name,label,defaultValue,fullWidth,onChange,placeholder}) {
  return    (   
                <TextField  label={label}
                            inputlabelprops={{
                              shrink: true,
                            }}
                            fullWidth={fullWidth}
                            multiline
                            rows={3}
                            defaultValue={defaultValue}
                            onChange={onChange}
                            name={name}
                            placeholder={placeholder}
                />
            )
}