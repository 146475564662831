import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Grid } from '@mui/material';


const DemoCarousel=({name,data,inputs,handleEvent})=>{

    const [selectDefault,setSelectDefault]  =   React.useState(0)

    const onClickThumb=(row)=>{
        if(name&&handleEvent&&data[row]){
            handleEvent(name,data[row])
        }
    }

    React.useEffect(()=>{
        if(!inputs[name]){
            handleEvent(name,data[0]) //definir por defecto el primero
        }
        if (selectDefault===0) {
            const result =  data.findIndex(search=>search.value===inputs[name])
            setSelectDefault(result)
        }
    },[handleEvent,inputs[name]])
    
    


    return (
        <Carousel
            selectedItem={selectDefault}
            onClickThumb={onClickThumb}
            thumbWidth={45}            
            showArrows={false}
        >
            {
                data.map((row,key)=>{
                    return  <div key={key}>
                                <img src={row.images} alt="Programandoweb" />
                                <Grid className="legend" sx={{ background: "rgba(0, 0, 0, 0.4) !important", }}>
                                    {row.label}
                                </Grid>                                
                            </div>
                })
            }
        </Carousel>
    );
    
}

export default DemoCarousel