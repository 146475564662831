import React from 'react';
import { Typography, Grid } from '@mui/material';
import Container    from '../../components/Theme/DashboardUsuario';
import vehiculo1 from '../../assets/png/vehiculo1.png';
import vehiculo2 from '../../assets/png/vehiculo2.png';
import vehiculo3 from '../../assets/png/vehiculo3.png';
import ImageActionButton from '../../components/Button/ImageActionButton';
import useAsyncStorage   from '../../hooks/useAsyncStorage';
import { useNavigate } from "react-router-dom";
import useAxios      from '../../hooks/useAxios';

const component                       =   'orden-servicios';
const step                            =   'step1';
let getInit;

const Web=()=>{
  const axios                         =   useAxios();
  const navigate                      =   useNavigate();
  const componentStorage              =   useAsyncStorage(component);
  const master                        =   useAsyncStorage("master");

  getInit=async()=>{
    axios.getData().then((response)=>{
      if (response&&response.data) {
        master.setData(response.data);
      }
    })
  }


  const handleEvent=async(name,type)=>{
    try {
      let _data = {}
      if (componentStorage.data===null) {
        _data[name] = type
      }else{  
        _data       = {...componentStorage.data}
        _data[name] = type
      }

      await componentStorage.setDataToStorage(component,_data)

      return navigate(component+"/"+step);

    } catch (error) {
      console.error(error)
    }    
  }

  React.useEffect(()=>{
    getInit()
  },[])


  return  <Container>
            <Grid>
              <Typography variant='h3' className='text-secondary' align="center">
                Tipo de carga
              </Typography>
              <Grid sx={{width:{xs:400,md:600}}}>
                <Grid container justifyContent={"center"}>
                  <Grid item xs={7} md={4}>
                    <Grid sx={{padding:{md:3,xs:1}}}>
                      <ImageActionButton event={handleEvent} name="tipo_carga_id" type="Refrigerada" src={vehiculo1} className="image-button" imgClassName='img-fluid' alt="Programandoweb"/>
                    </Grid>
                  </Grid>
                  <Grid item xs={7} md={4}>
                    <Grid sx={{padding:{md:3,xs:1}}}>
                      <ImageActionButton event={handleEvent} name="tipo_carga_id" type="Seca" src={vehiculo2} className="image-button" imgClassName='img-fluid' alt="Programandoweb"/>
                    </Grid>
                  </Grid>
                  <Grid item xs={7} md={4}>
                    <Grid sx={{padding:{md:3,xs:1}}}>
                      <ImageActionButton event={handleEvent} name="tipo_carga_id" type="Granel" src={vehiculo3} className="image-button" imgClassName='img-fluid' alt="Programandoweb"/>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
}
export default Web

