/*set components*/
import Home  from './Home';
import Auth  from '../Auth';

/*set icons*/
import HomeIcon from '@mui/icons-material/Home';

export const routes_modules = [
  {
    name: "Home",
    path: "/",
    component: Home,
    menu: false,
    private: true,
    icon: <HomeIcon />,
  }, 
  {
    name: "Auth",
    path: "/auth*",
    component: Auth,
    menu: false,
    private: true,
    icon: <HomeIcon />,
  },  
];
