import Container from '../../components/Theme/Auth';
import Input from '../../components/Input';
import Submit from '../../components/Button/Loading';
import Button from '@mui/material/Button';

import { useContext } from 'react';
import useFormData      from '../../hooks/useFormData';
import useAxios         from '../../hooks/useAxios';
import useAsyncStorage  from '../../hooks/useAsyncStorage';
import StateContext from '../../helpers/ContextState';
import {useNavigate, useLocation, NavLink } from "react-router-dom";

const Login=()=>{
  const master                                =   useAsyncStorage("master");
  const { formData, onChange, setFormData }   =   useFormData();
  const axios                                 =   useAxios();
  const context                               =   useContext(StateContext);
  const userStorage                           =   useAsyncStorage("user");
  const location                              =   useLocation();
  const navigate                              =   useNavigate();

  const onSubmit=(e)=>{
    e.preventDefault()
    sendDataBK();
  }

  //recover_password
  const sendDataBK=(__data)=>{
    axios.postData({...formData,...__data}).then((response)=>{
      if (response&&response.message&&response.message==='Hemos enviado un email a su cuenta para continuar con el proceso.') {
        return navigate("/auth/forgotPassword2");  
      }
    })
  }

  return  <Container onSubmit={onSubmit}>
            <Input size="small" required onChange={onChange} fullWidth name="email" type="email" label="Correo electrónico"/>
            <Submit fullWidth type="submit" label="Recuperar Contraseña"/>
            <Button to="/auth/register" component={NavLink}>Crear Cuenta</Button>
            <Button to="/auth" component={NavLink}>Iniciar sesión</Button>
          </Container>
}
export default Login
