import { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import StateContext from '../helpers/ContextState';
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import useAsyncStorage   from './useAsyncStorage';

const BACKEND = (window.location.hostname === 'localhost'  || window.location.hostname === 'salamina.evolve.test') ? process.env.REACT_APP_BACKEND : process.env.REACT_APP_BACKENDREMOTE;

const useAxios = () => {
  const user                =   useAsyncStorage("user");
  const location              =   useLocation();
  const navigate              =   useNavigate();
  const context               =   useContext(StateContext);
  const [data, setData]       =   useState(null);
  const [url, setUrl]         =   useState(BACKEND + "api/" + process.env.REACT_APP_BACKEND_VERSION + location.pathname);
  const token                 =   localStorage.getItem('user') || null;

  useEffect(() => {
    setUrl(BACKEND + "api/" + process.env.REACT_APP_BACKEND_VERSION + location.pathname)
  }, [location]);

  const getData = async (requestData, endpoint) => {

    const me  =   JSON.parse(localStorage.getItem('user'))

    context.setLoading(true);


    let _url  =   url;
    if (endpoint) {
      _url    =   BACKEND + endpoint;
    }

    try {
      const response = await axios.get(_url, {
        headers: {
          Authorization: token ? `Bearer ${me.access_token}` : null,
        },
        params: requestData, // Aquí pasamos los parámetros en el objeto requestData
      });
      context.setLoading(true);

      return response.data
    } catch (err) {
      if (err&&err.response&&err.response.statusText&&err.response.statusText==='Unauthorized') {
        /*corregir*/
        await user.removeDataFromStorage("user")
        return navigate("/auth/login");
      }else if (err.response && err.response.data && err.response.data.message) {
        context.setOpenMessage(err.response.statusText + ": " + err.response.data.message);
      }
    } finally {
      context.setLoading(false);
    }
  };

  const postData = async (requestData, endpoint, skipMessage=false) => {

    const me  =   JSON.parse(localStorage.getItem('user'))

    context.setLoading(true);

    let _url = false;
    if (endpoint) {
      _url = BACKEND + endpoint;
    }

    try {
      //return console.log(_url , url,me.access_token,requestData);
      const response = await axios.post(_url || url, requestData, {
        headers: {
          Authorization: token ? `Bearer ${me.access_token}` : null,
        },
      });

      if (response.data && response.data.code && response.data.code === 'ERROR') {
        context.setOpenMessage(response.data.code + ": " + response.data.message)
      }
      

      if (response.data && response.data.message && response.data.status && response.data.status==='error' && !skipMessage) {
        context.setOpenMessage(response.data.message)
      }  else if (response && response.data && response.data.code && response.data.code==='SUCCESS' && response.data.message && !skipMessage) {
        context.setOpenMessage({message:response.data.message, data:response.data})
      }else if (response && response.data && response.data.message && response.data.message==='Validation errors' && response.data.data && !skipMessage) {
        let error_message = "";
        
        Object.entries(response.data.data).map((row,key)=>{
          return error_message  +=  row[0]+" : "+row[1] + ((key+1) !== Object.entries(response.data.data).length?",":"");
        })
        
        return context.setOpenMessage({message:response.data.message+" "+error_message})
      }   

      if (response && response.data) {
        setData(response.data);
        return response.data;
      }

    } catch (err) {

      
      
      if (err&&err.response&&err.response.statusText&&err.response.statusText==='Unauthorized') {
        /*corregir*/
        await user.removeDataFromStorage("user")
        return navigate("/auth/login");
      }
      
      if (err.code==='ERR_BAD_REQUEST'&&err.response&&err.response.data&&err.response.data.message) {
        if (err.response.data.errors) {
          let message_new = ''
          Object.entries(err.response.data.errors).map((row)=>{
            if(row[0]&&row[1]){
              return message_new += row[0]+ ": "+row[1]+", "
            } 
            return message_new;
          })
          context.setOpenMessage(err.response.data.message+" "+message_new)  
        }          

      }
      if (err.response && err.response.data && err.response.data.code && err.response.data.message) {
        context.setOpenMessage(err.response.data.code + ": " + err.response.data.message)
      }else if (err.response && err.response.data && err.response.data.message&&!err.response.data.errors) {
        context.setOpenMessage(err.response.statusText + ": " + err.response.data.message);
      }
    } finally {
      context.setLoading(false);
    }
  };


  const postDataAttachment = async (requestData, endpoint, skipMessage=false) => {
    const me = JSON.parse(localStorage.getItem('user'));

    context.setLoading(true);

    let _url = false;
    if (endpoint) {
      _url = BACKEND + endpoint;
    }

    try {
      const formData = new FormData();
      Object.entries(requestData).map((row,key)=>{
        return formData.append(row[0], row[1]);
      })

      const response = await axios.post(_url || url, formData, {
        headers: {
          Authorization: token ? `Bearer ${me.access_token}` : null,
          'Content-Type': 'multipart/form-data', // Establecer la cabecera adecuada para enviar archivos
        },
      });

      context.setLoading(false);

      if (response.data && response.data.code && response.data.code === 'ERROR') {
        context.setOpenMessage(response.data.code + ": " + response.data.message);
      } else if (response && response.data && response.data.code && response.data.code==='SUCCESS' && response.data.message && !skipMessage) {
        context.setOpenMessage({message:response.data.message, data:response.data})
      }   
      if (response && response.data) {
        setData(response.data);
        return response.data;
      }
    } catch (err) {
      
      if (err&&err.response&&err.response.statusText&&err.response.statusText==='Unauthorized') {
        /*corregir*/
        await user.removeDataFromStorage("user")
        return navigate("/auth/login");
      }
      if (err.response && err.response.data && err.response.data.code && err.response.data.message) {
        context.setOpenMessage(err.response.data.code + ": " + err.response.data.message);
      }else if (err.response && err.response.data && err.response.data.message&&!err.response.data.errors) {
        context.setOpenMessage(err.response.statusText + ": " + err.response.data.message);
      }
    } finally {
      context.setLoading(false);
    }
  };


  const putData = async (requestData, endpoint, skipMessage=false) => {

    const me  =   JSON.parse(localStorage.getItem('user'))

    context.setLoading(true);

    let _url = false;
    if (endpoint) {
      _url = BACKEND + endpoint;
    }

    try {
      const response = await axios.put((_url || url)+(requestData.id?"/"+requestData.id:""), requestData, {
        headers: {
          Authorization: token ? `Bearer ${me.access_token}` : null,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      if (response.data && response.data.code && response.data.code === 'ERROR') {
        context.setOpenMessage(response.data.code + ": " + response.data.message)
      }else if (response && response.data && response.data.code && response.data.code==='SUCCESS' && response.data.message && !skipMessage) {
        context.setOpenMessage({message:response.data.message, data:response.data})
      } 
      if (response && response.data) {
        setData(response.data);
        return response.data;
      }
    } catch (err) {
      if (err&&err.response&&err.response.statusText&&err.response.statusText==='Unauthorized') {
        /*corregir*/
        await user.removeDataFromStorage("user")
        return navigate("/auth/login");        
      }
      if (err.response && err.response.code && err.response.message) {
        context.setOpenMessage(err.response.code + ": " + err.response.message)
      }

    } finally {
      context.setLoading(false);
    }
  };

  const deleteData = async (endpoint) => {

    const me  =   JSON.parse(localStorage.getItem('user'))

    context.setLoading(true);


    let _url = false;
    if (endpoint) {
      _url = BACKEND + endpoint;
    }

    try {
      await axios.delete(_url || url, {
        headers: {
          Authorization: token ? `Bearer ${me.access_token}` : null,
        },
      });
      setData(null);
      return true;
    } catch (err) {
        if (err.response && err.response.code && err.response.message) {
          context.setOpenMessage(err.response.code + ": " + err.response.message)
        }

      } finally {
        context.setLoading(false);
      }
      };


      return {
        data,
        postData,
        postDataAttachment,
        putData,
        deleteData,
        getData, // Función para realizar una solicitud GET
      };
    };

    export default useAxios;
