import { Grid, Typography } from "@mui/material"
import "./index.css"
import image from '../../assets/png/imagen-header.png';
import Menu from './Menu';

const Header=()=>{
    return  <Grid component={"header"}>
                <Grid sx={{pt:3}}>
                    <Menu/>
                </Grid>                
            </Grid>
}
export default Header