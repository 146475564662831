import {useEffect} from 'react';
import Grid  from  '@mui/material/Grid';
import { Card, CardContent, Typography } from '@mui/material';
import Container    from '../../components/Theme/DashboardAdmin';
import useAxios      from '../../hooks/useAxios';
import useFormData  from '../../hooks/useFormData';
import { NavLink } from 'react-router-dom';
let getInit;
const Web=()=>{
  const axios                       =   useAxios();
  const { formData, setFormData }   =   useFormData();

  getInit=()=>{
    const endpoint  =   "api/"+process.env.REACT_APP_BACKEND_VERSION+window.location.pathname+""
    axios.getData({},endpoint).then((response)=>{
      if (response&&response.data) {
        setFormData(response.data)
      }
    })
  }

  useEffect(()=>{
    let mounted =   true
    getInit(mounted)
    return function cleanup() {
      mounted   =   false
    }
  },[])

  return  <Container>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Card variant="cardHome">
                    <CardContent align="center">
                      
                        <Typography variant="h5" component="div">
                          Clientes
                        </Typography>
                        <Grid container>
                          <Grid item xs={4}>
                              <Typography variant="body2">
                                Activos 
                              </Typography>
                              <Typography variant="h5" component="div">
                                {formData.total_usuarios_x_status&&formData.total_usuarios_x_status.active?formData.total_usuarios_x_status.active:0}
                              </Typography>
                            
                          </Grid>
                          <Grid item xs={4}>
                            
                              <Typography variant="body2">
                                Solicitudes
                              </Typography>
                              <Typography variant="h5" component="div">
                                {formData.total_usuarios_x_status&&formData.total_usuarios_x_status.stanby?formData.total_usuarios_x_status.stanby:0}
                              </Typography>
                            
                          </Grid>
                          <Grid item xs={4}>
                            
                              <Typography variant="body2">
                                Rechazados
                              </Typography>
                              <Typography variant="h5" component="div">
                                {formData.total_usuarios_x_status&&formData.total_usuarios_x_status.reject?formData.total_usuarios_x_status.reject:0}
                              </Typography>
                            
                          </Grid>
                        </Grid>                                              
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Card variant="cardHome">
                    <CardContent align="center">
                      <NavLink to="/dashboard/services">
                        <Typography variant="h5" component="div">
                          Servicios
                        </Typography>
                        <Typography variant="body2">
                          Abiertas
                        </Typography>
                        <Typography variant="h5" component="div">
                          {formData.total_services}                          
                        </Typography>
                      </NavLink>  
                    </CardContent>
                  </Card>
                </Grid>
                {
                  /*
                    <Grid item xs={12} md={3}>
                      <Card variant="cardHome">
                        <CardContent align="center">
                          <Typography variant="h5" component="div">
                            Etiquetas
                          </Typography>
                          <Typography variant="body2">
                            Total registrados 
                          </Typography>
                          <Typography variant="h5" component="div">
                            {formData.etiquetas}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  */
                }                
              </Grid>
          </Container>
}
export default Web

