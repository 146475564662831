import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, InputLabel, TextField, LinearProgress } from '@mui/material';

const PasswordStrengthMeter = ({ name, label, id, defaultValue, onChange, placeholder, fullWidth, required, sx, maxLength }) => {
  const [password, setPassword] = useState(defaultValue || '');

  const calculatePasswordStrength = () => {
    // Expresiones regulares para verificar la presencia de mayúscula, número y carácter especial
    const uppercaseRegex = /[A-Z]/;
    const numberRegex = /[0-9]/;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;

    // Verificar la presencia de mayúscula, número y carácter especial
    const hasUppercase = uppercaseRegex.test(password);
    const hasNumber = numberRegex.test(password);
    const hasSpecialChar = specialCharRegex.test(password);

    // Calcular la longitud de la contraseña y asegurarse de que sea al menos 8 caracteres
    const lengthStrength = Math.min(password.length / 8, 1);
    
    // Asegurar que la fuerza esté entre 0 y 100
    const strength =  ((lengthStrength===1)?25:0) +
                      ((hasUppercase)?25:0) +
                      ((hasNumber)?25:0) +
                      ((hasSpecialChar)?25:0) 

    return strength;
  };

  const passwordStrength = calculatePasswordStrength();

  return (
    <Box sx={sx || { mb: 2 }}>
      <InputLabel>{label}</InputLabel>
      <TextField
        inputlabelprops={{
          shrink: true,
        }}
        required={required || false}
        fullWidth={fullWidth}
        name={name}
        label={label}
        id={id}
        value={password}
        variant="outlined"
        onChange={(e) => {
          setPassword(e.target.value);
          onChange(e);
        }}
        placeholder={placeholder}
        type="password"
      />
      <LinearProgress
        variant="determinate"
        value={passwordStrength}
        sx={{ mt: 1, height: 5 }}
        color={passwordStrength === 100 ? 'success' : 'primary'}
      />
    </Box>
  );
};

PasswordStrengthMeter.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  sx: PropTypes.object,
};

export default PasswordStrengthMeter;
