import React from 'react';
import Container from '../../components/Theme/AuthForm';
import Input from '../../components/Input/InputLabel';
import InputLabelPassword from '../../components/Input/InputLabelPassword';
import useFormData  from '../../hooks/useFormData';
import { Button, Grid, Typography , Alert , Paper} from '@mui/material';
import Upload from '../../components/Cropper/UploadNew3';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from "react-router-dom";
import StateContext from '../../helpers/ContextState';
import useAsyncStorage   from '../../hooks/useAsyncStorage';
import useAxios     from '../../hooks/useAxios';
import RegisterContract2 from './RegisterContract2';

const storage =   "register-user"
const label   = { inputProps: { 'aria-label': 'Acepto los términos' } };
const Login=()=>{
  const axios                   =   useAxios();
  const context                 =   React.useContext(StateContext)
  const navigate                =   useNavigate();
  const userStorage             =   useAsyncStorage(storage);
  const [accept,setAccept]      =   React.useState(false);
  //const [data,setData]          =   React.useState(false);
  const { 
          formData,
          onChange,
          setFormData 
        } =   useFormData();

  const onSubmit = async (e)=>{
    e.preventDefault()


    if (!formData.rut||!formData.cedula) {
      
      return context.setOpenDialog({
        component:  <Paper sx={{p:2}}>
                      <Alert severity="warning">
                          Es importante completar todos los documentos requeridos
                      </Alert>
                      <Grid component={"ul"}>
                        {
                          !formData.rut&&(<Grid component={"li"}>
                            <Typography>
                              Es requerido el RUT
                            </Typography>
                          </Grid>)
                        }                        
                        {
                          !formData.cedula&&(<Grid component={"li"}>
                            <Typography>
                              Es requerido la cédula
                            </Typography>
                            </Grid>)
                        }                        
                        
                      </Grid>
                      <Grid align="right">
                        <Button variant="contained" onClick={()=>context.setOpenDialog(false)}>Cerrar</Button>
                      </Grid>
                    </Paper>
      })
      
    }

    
    
    if (formData.password!==formData.password2) {
      return context.setOpenDialog({
        component:  <Paper sx={{p:2}}>
                      <Alert severity="error">
                          Problemas de validación
                      </Alert>
                      <Grid component={"ul"}>                        
                            <Typography>
                              Las contraseñas no coinciden, por favor vuelve a intentarlo.
                            </Typography>   
                      </Grid>
                      <Grid align="right">
                        <Button variant="contained" onClick={()=>context.setOpenDialog(false)}>Cerrar</Button>
                      </Grid>
                    </Paper>
      })
    }

    context.setOpenDialog({
      loading:true
    })


    await axios.postData(formData).then((response)=>{
      if (response&&response.status==='success') {
        return navigate("/auth/register_response");
      }
    })
    
    context.setOpenDialog(false);
    return;
    
  }

  const contract=()=>{

    context.setOpenDialog({
      component:  <Paper sx={{p:2}}>
                    <RegisterContract2/>
                    <Grid align="right">
                      <Button variant="contained" onClick={()=>{context.setOpenDialog(false)}}>Cerrar</Button>
                    </Grid>
                  </Paper>
    })
    
  }

  
  return  <Container label="Datos de registro"  onSubmit={onSubmit}>
              <Grid container spacing={2}>                
                <Grid item xs={12} md={6}>
                  <Input
                    variant="border"
                    sx={{mb:{md:1,xs:0},width:"100%"}} 
                    required 
                    onChange={onChange}  
                    fullWidth 
                    name="name" 
                    type="text" 
                    label="Nombre del contacto"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    variant="border"
                    sx={{mb:{md:1,xs:0},width:"100%"}} 
                    required 
                    onChange={onChange}  
                    fullWidth 
                    name="surname" 
                    type="text" 
                    label="Apellidos del contacto"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    autoComplete={"off"}
                    variant="border"
                    sx={{mb:{md:1,xs:0},width:"100%"}} 
                    required 
                    onChange={onChange}  
                    fullWidth 
                    name="email" 
                    type="email" 
                    label="Correo electrónico"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    variant="border"
                    sx={{mb:{md:1,xs:0},width:"100%"}} 
                    required 
                    onChange={onChange}  
                    fullWidth 
                    name="celular" 
                    type="text" 
                    label="Teléfono"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabelPassword
                    autoComplete={"off"}
                    variant="border"
                    sx={{mb:{md:1,xs:0},width:"100%"}} 
                    required 
                    onChange={onChange}  
                    fullWidth 
                    name="password" 
                    type="password" 
                    label="Clave"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    variant="border"
                    sx={{mb:{md:1,xs:0},width:"100%"}} 
                    required 
                    onChange={onChange}  
                    fullWidth 
                    name="password2" 
                    type="password" 
                    label="Verificar Clave"
                  />
                </Grid>
                <Grid item xs={12} >
                  <Typography variant="h6">
                    Documentos adjuntos
                  </Typography>  
                </Grid>
                <Grid item xs={12} md={6}>
                  <Upload context={context} onChange={setFormData} data={formData} name="rut" label="RUT"/>                  
                </Grid>
                <Grid item xs={12} md={6}>
                  <Upload context={context} onChange={setFormData} data={formData} name="cedula" label="Cédula"/>                  
                </Grid>

                <Grid item xs={12} align="right">
                  <Typography >
                    <Grid component={"span"} className='cursor-pointer' onClick={contract}>¿Acepta los términos?</Grid> <Checkbox {...label} onClick={(e) => { setAccept(e.target.checked) }} />
                  </Typography>
                  <Button variant='contained' type="submit" disabled={!accept?true:false}>
                    Crear Cuenta
                  </Button>
                </Grid>
              </Grid>              
          </Container>
}
export default Login
