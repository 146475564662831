import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

const TextFieldSizes = ({   name, 
                            label, 
                            id, 
                            defaultValue, 
                            variant, 
                            size, 
                            onChange, 
                            placeholder, 
                            fullWidth, 
                            type, 
                            required, 
                            sx, 
                            disabled , 
                            max}) => {
  const  [value,setValue] = React.useState(defaultValue||"")

  const handleOnChange=(e)=>{
    if (  max && 
          e && 
          e.target&&
          e.target.value&&
          parseFloat(e.target.value)>parseFloat(max)) {
      e.preventDefault()
      return;
    }
    setValue(e.target.value)
    console.log(onChange,parseFloat(e.target.value))
    onChange(e)
  }

  return (
    <Box sx={sx||{mb:2}}>
      <TextField
        value={value}
        disabled={disabled}
        required={required||false}
        type={type||"text"}
        fullWidth={fullWidth}
        inputlabelprops={{
          shrink: false,
        }}
        name={name}
        label={label}
        id={id}
        /*defaultValue={defaultValue}*/
        variant={variant||"outlined"}
        size={size}
        onChange={handleOnChange}
        placeholder={placeholder}
      />
    </Box>
  );
};

TextFieldSizes.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default TextFieldSizes;
