import * as React from 'react';
import { routes_modules } from './routesUsuario';
import {Box,Grid}                from '@mui/material';
import useMyRoutes        from '../../hooks/useMyRoutes';
import StateContext       from '../../helpers/ContextState';
import Menu from '../../components/Menu';
import MenuMovil from '../../components/Menu/MenuMovil';


const Auth=()=>{
  const context = React.useContext(StateContext);
  return  <Box sx={{display:context.loadingApiRest||context.hide?"none":"block"}} className="dashboard-content">
            <Grid container justifyContent={"center"}>
                <Grid item xs={11}>
                  <Grid container sx={{pt:4}}>
                    <Grid item xs={12} md={2} >
                      <Grid sx={{display:{xs:"none",md:"block"}}}>
                        <Menu items={routes_modules}/>
                      </Grid>
                      <Grid sx={{display:{md:"none",xs:"block"}}}>
                        <MenuMovil items={routes_modules}/>
                      </Grid>                        
                    </Grid>
                    <Grid item xs={12} md={10} >
                      {useMyRoutes(routes_modules)}                      
                    </Grid>
                  </Grid>
                </Grid>
            </Grid>
            
          </Box>
}

export default Auth;
